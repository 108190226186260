import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import Util = require("Everlaw/Util");

module DeleteEntityConfirmation {
    export function confirmDeletion<T>(
        property: string,
        entityName: string,
        valueName: string,
        onSuccess: () => any,
    ): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            Rest.post("search/searchInfo.rest", { eql: property })
                .then((sr) => {
                    const count = parseInt(sr.numResults);
                    if (count > 0) {
                        QueryDialog.create({
                            title: `Delete ${entityName} confirmation`,
                            prompt:
                                `Are you sure you want to delete this ${entityName}? Its ${valueName} `
                                + `will be removed from ${Util.countOf(count, "document")}.`,
                            submitIsSafe: false,
                            submitText: "Delete",
                            onSubmit: () => {
                                resolve(onSuccess());
                                return true;
                            },
                            onCancel: () => {
                                reject();
                                return true;
                            },
                        });
                    } else {
                        resolve(onSuccess());
                    }
                })
                .catch(reject);
        });
    }
}

export = DeleteEntityConfirmation;
