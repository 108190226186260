/**
 * Files - Helper functions for working with path names and the local filesystem. Also provides a
 * Picker widget for selecting files rooted at a given DirectoryEntry.
 */
import Arr = require("Everlaw/Core/Arr");
import Cmp = require("Everlaw/Core/Cmp");
import DateUtil = require("Everlaw/DateUtil");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import Str = require("Everlaw/Core/Str");
import Ticker = require("Everlaw/EntryPoints/WebWorkers/Ticker");
import Util = require("Everlaw/Util");
import on = require("dojo/on");
import { userHasFirefox } from "Everlaw/Core/Sniff";

// Interfaces for the experimental HTML5 Entry API. WARNING this is not available in all browsers.
export interface Entry {
    isFile: boolean;
    isDirectory: boolean;
    name: string;
    fullPath: string;
    getMetadata(callback: (md: Metadata) => void, onError?: (evt: Error) => void): void;
}

export interface DirectoryEntry extends Entry {
    createReader(): DirectoryReader;
    getFile: GetEntry<FileEntry>;
    getDirectory: GetEntry<DirectoryEntry>;
}

export interface FileEntry extends Entry {
    file(callback: (file: File) => void, onerror?: (evt: Error) => void): void;
}

/**
 * Since FileEntry properties aren't "enumerable own" properties (e.g., for the isFile property,
 * fileEntry.propertyIsEnumerable("isFile") returns false), they won't be serialized into JSON. This
 * remedies that by explicitly repackaging the desired properties into a different type. This
 * excludes the "getMetadata" and "file" methods, so some manipulation is required to get this back
 * into an actual FileEntry.
 */
export type FileEntryJSON = Omit<FileEntry, "file" | "getMetadata">;

export interface DirectoryReader {
    readEntries(callback: (entries: Entry[]) => void, onerror?: (evt: Error) => void): void;
}

export interface Metadata {
    modificationTime: Date;
    size: number;
}

type GetEntry<E extends Entry> = (
    path: string,
    flag?: {
        create?: boolean;
        exclusive?: boolean;
    },
    success?: (entry: E) => void,
    error?: (evt: Error) => void,
) => void;

export function isAncestorOf(ancestor: string, descendant: string) {
    if (ancestor === descendant) {
        return true;
    }
    if (descendant.length > ancestor.length && Str.startsWith(descendant, ancestor)) {
        var sep = descendant[ancestor.length];
        return sep === "/" || sep === "\\";
    }
    return false;
}

export interface WalkParams {
    root: DirectoryEntry;
    withFiles: (fileEntries: FileEntry[], dirEntry: DirectoryEntry) => Promise<void>;
    walkDir?: (dirEntry: DirectoryEntry) => boolean;
    batchSize?: number;
}

export function walk(walkParams: WalkParams): Promise<void> {
    return new Walker(walkParams).walk();
}

export function getSegments(path: String) {
    if (!path) {
        return [];
    }
    return path.split("/").filter((part) => !!part);
}

/**
 * Whenever a walk is requested, we generate a promise for it and return that to the caller.
 * We then store the promise resolve/reject callbacks (as well as the walk halt method) in a
 * WalkPromise object, since the walk is evaluated asynchronously.
 */
interface WalkPromise {
    halt: () => boolean;
    resolve: () => void;
    reject: () => void;
}

/**
 * Walks a file hierarchy starting at a given rootDirEntry, calling withFiles with batches of one or
 * more files in each directory (the batch size is configurable; the default is to use the batches
 * the directory walker returns. Each child directory is walked iff the optional walkDir callback
 * returns true (the default) for that directory.
 */
export class Walker {
    static readonly BATCH_DEFAULT = 0;
    private dirEntries: DirectoryEntry[];
    private fileEntries: FileEntry[] = [];
    private dirEntry: DirectoryEntry;
    private entryReader: DirectoryReader;
    private requestedWalks: WalkPromise[] = [];
    private currWalk: WalkPromise;
    private withFiles: (fileEntry: FileEntry[], dirEntry: DirectoryEntry) => Promise<void>;
    private walkDir: (dirEntry: DirectoryEntry) => boolean;
    private batchSize: number;
    constructor(walkParams: WalkParams) {
        this.withFiles = walkParams.withFiles;
        this.walkDir = walkParams.walkDir || ((dirEntry: DirectoryEntry) => true);
        this.batchSize = walkParams.batchSize || Walker.BATCH_DEFAULT;
        this.dirEntries = [walkParams.root];
    }
    walk(halt = () => false): Promise<void> {
        // Individual walks happen asynchronously and in segements. When a request for a new walk
        // comes in, we don't want it to interleave with an ongoing walk, so instead we queue it.
        return new Promise<void>((resolve, reject) => {
            this.requestedWalks.push({ halt, resolve, reject });
            if (!this.currWalk) {
                this.walkNext();
            }
        }).finally(() => {
            // After a walk finishes, start the next one.
            this.walkNext();
        });
    }
    private walkNext() {
        this.currWalk = this.requestedWalks.shift();
        if (this.currWalk) {
            this.walkDirEntries(this.currWalk);
        }
    }
    private walkDirEntries(walk: WalkPromise) {
        if (walk.halt()) {
            walk.resolve();
        } else if (this.fileEntries.length > 0) {
            let nextBatch: FileEntry[];
            // Walk the file entries we've read in smaller batches as well (if requested).
            if (this.batchSize === Walker.BATCH_DEFAULT) {
                nextBatch = this.fileEntries;
                this.fileEntries = [];
            } else {
                nextBatch = this.fileEntries.slice(0, this.batchSize);
                this.fileEntries = this.fileEntries.slice(this.batchSize);
            }
            this.withFiles(nextBatch, this.dirEntry)
                .then(() => {
                    this.walkDirEntries(walk);
                })
                .catch(walk.reject);
        } else if (this.entryReader) {
            this.entryReader.readEntries((entries) => {
                if (entries.length > 0) {
                    entries.forEach((entry) => {
                        if (entry.isFile) {
                            this.fileEntries.push(<FileEntry>entry);
                        } else if (entry.isDirectory) {
                            if (this.walkDir(<DirectoryEntry>entry)) {
                                this.dirEntries.push(<DirectoryEntry>entry);
                            }
                        } else {
                            console.log("Unknown entry " + entry.fullPath);
                        }
                    });
                    this.walkDirEntries(walk);
                } else {
                    // We're done with this directory.
                    this.dirEntry = null;
                    this.entryReader = null;
                    this.walkDirEntries(walk);
                }
            }, walk.reject);
        } else if (this.dirEntries.length > 0) {
            // Start reading the topmost directory.
            this.dirEntry = this.dirEntries.pop();
            this.entryReader = this.dirEntry.createReader();
            this.walkDirEntries(walk);
        } else {
            walk.resolve();
        }
    }
}

/**
 * Loads the DirectoryEntry object for the directory at `path` relative to `dirEntry`.
 */
export function getDirectory(dirEntry: DirectoryEntry, path: string) {
    return new Promise<DirectoryEntry>(function (resolve, reject) {
        dirEntry.getDirectory(path, null, resolve, reject);
    });
}

/**
 * Loads the HTML5 File object for the file at `path` relative to `dirEntry`. If loading the
 * FileEntry and it's corresponding File object takes longer than `timeout` milliseconds then the
 * load times out (but note that the asynchronous operation cannot be canceled.
 */
export function getFile(dirEntry: DirectoryEntry, path: string, timeout?: number) {
    if (userHasFirefox() && !(dirEntry instanceof VirtualDirectory)) {
        // Firefox searches relative files to "/root_dir/", while Chrome searches relative to "/"
        path = path.replace(dirEntry.fullPath + "/", "");
    }
    var p = new Promise<File>(function (resolve, reject) {
        dirEntry.getFile(
            path,
            null,
            function (entry) {
                entry.file(resolve, reject);
            },
            reject,
        );
    });
    return timeout ? Ticker.timeout(p, timeout, "Timeout during asychronous file loading") : p;
}

/**
 * Loads the HTML5 File objects for all of the given fileEntries, returning a Promise that resolves
 * when all of them have been loaded. The elements of the resulting File[] are in the same order as
 * in fileEntries.
 */
export function load(fileEntries: FileEntry[]) {
    return Promise.all(
        fileEntries.map(function (entry) {
            return new Promise<File>(function (resolve, reject) {
                entry.file(resolve, reject);
            });
        }),
    );
}

export function loadMetadata(fileEntries: FileEntry[]) {
    return Promise.all(
        fileEntries.map(function (entry) {
            return new Promise<Metadata>(function (resolve, reject) {
                if (entry.getMetadata) {
                    entry.getMetadata(resolve, reject);
                } else {
                    entry.file((file) => {
                        resolve({
                            size: file.size,
                            modificationTime: DateUtil.asDate(file.lastModified),
                        });
                    }, reject);
                }
            });
        }),
    );
}

/**
 * Read and return the text from the given File object. This loads the entire text into memory
 * at once, so use with caution. Default encoding is UTF-8.
 */
export async function readTextFile(file: File, encoding = "UTF-8"): Promise<string> {
    return new Promise<string>((resolve) => {
        var fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result as string);
        fileReader.readAsText(file, encoding);
    });
}

export const enum Selectable {
    NONE = 0,
    FILES = 1,
    DIRS = 2,
    BOTH = FILES | DIRS,
}

type PathSelections = { [parent: string]: boolean | PathSelections };

/**
 * An interface element for displaying and selecting the contents of a drag-and-dropped folder. Its
 * getNode method provides a node to be inserted into the DOM. The params argument to the
 * constructor allows for providing hooks into various events, overriding the standard classes
 * applied to DOM elements, and specifying the HTML messages to use when instructing the user to
 * drag and drop the folder.
 *
 * This implements much of the UI.Widget interface, but it is not a subclass because we don't want
 * to use the UI.Widget constructor.
 */
export class Picker {
    // overridable properties

    /**
     * Called each time the user selects a file or directory in the picker.
     */
    onSelect(entry: Entry, selected: Entry[]): void {}
    /**
     * Called each time the user deselects a previously-selected file or directory. When destroying
     * is true, it means that onDeselect is being called while the picker is being destroyed.
     */
    onDeselect(entry: Entry, selected: Entry[], destroying: boolean): void {}
    /**
     * Called each time a new element is selected or deselected, after either onSelect or onDeselect
     * is called. When destroying is true, it means that onChange is being called while the picker
     * is being destroyed.
     */
    onChange(selected: Entry[], destroying: boolean): void {}

    /**
     * Because some directories have many files, we only display this many files when loading a
     * directories' contents. This saves memory and screen real estate.
     */
    maxDirFiles = 20;

    /**
     * When true, at most one item can be selected at a time. Selecting a second item will deselect
     * the currently-selected item.
     */
    selectOne = false;

    /**
     * The fullPaths of entries that cannot be selected. This list may be reassigned or modified
     * after construction, but any already-selected items will remain selected.
     */
    disabled: string[] = [];

    // readonly/private properties

    /**
     * The Widgets corresponding to the entries that were provided to the constructor or changeRoot.
     */
    toplevel: EntryWidget[] = [];

    /**
     * The list of selected files and folders, each an HTML5 Entry object where either isFile or
     * isDirectory is true. It goes without saying that if only directories or only files are
     * selectable, then this list will only contain elements of that type.
     *
     * This list should be considered readonly; do not modify it.
     */
    selected: Entry[] = [];

    /**
     * The array of selected FileWidget and DirWidget objects.
     */
    private selectedWidgets: EntryWidget[] = [];

    private destroying = false;

    /**
     * Creates a new Files.Picker.
     *
     * @param entries           the toplevel file entries to show in the picker
     * @param selectable        the types of files that can be selected
     */
    constructor(
        entries: Entry[] | Entry,
        public selectable: Selectable,
        // The outermost node.
        public node: HTMLElement = Dom.div({ class: "picker-container" }),
        // The node that contains the toplevel widgets (emptied by changeRoot).
        protected rootNode = node,
    ) {
        this.changeRoot(entries);
    }

    /**
     * Changes the root directories to the given entries, instead of the entries that were passed
     * into the constructor or a previous call to changeRoot.
     */
    changeRoot(entries: Entry[] | Entry) {
        Dom.empty(this.rootNode);
        this.destroyToplevel();
        // We'll collect all the file and directory entries, filtering out the nulls that we return
        // when an entry is neither.
        Arr.wrap(entries).forEach((entry) => {
            var entryWidget: EntryWidget;
            if (entry.isDirectory) {
                entryWidget = this.newDirWidget(<DirectoryEntry>entry, true);
            } else if (entry.isFile) {
                entryWidget = this.newFileWidget(<FileEntry>entry, true);
            } else {
                skipping(entry);
                return;
            }
            this.addEntryToNode(entryWidget);
            this.toplevel.push(entryWidget);
        });
    }

    selectDirs(fullPaths: string[]) {
        if (fullPaths.length > 0) {
            var topDirs = <DirWidget[]>this.toplevel.filter((w) => w instanceof DirWidget);
            if (topDirs.length > 0) {
                return this.selectFrom(topDirs, fullPaths);
            }
        }
        return Promise.resolve(null);
    }

    /**
     * Selects the specified file if it exists.
     */
    selectFile(path: string): void {
        const topDirs = this.toplevel.filter((w) => w instanceof DirWidget) as DirWidget[];
        if (topDirs.length > 0) {
            this.selectFileFromDirs(topDirs, path);
        }
    }

    /**
     * Deselects the currently selected entries.
     */
    clearSelections() {
        // We have to make a copy because selectedWidgets will be modified in the course of
        // deselecting.
        this.selectedWidgets.slice().forEach(function (w) {
            w.deselect();
        });
    }

    destroy() {
        this.destroying = true;
        this.destroyToplevel();
    }

    /**
     * A method for use by an EntryWidget to indicate that it has been deselected.
     */
    deselect(entryWidget: EntryWidget) {
        var entry = entryWidget.entry;
        if (Arr.remove(this.selectedWidgets, entryWidget)) {
            Arr.remove(this.selected, entry);
            this.onDeselect(entry, this.selected, this.destroying);
            this.onChange(this.selected, this.destroying);
        }
    }

    newDirWidget(entry: DirectoryEntry, toplevel: boolean) {
        var dw = new DirWidget(entry, this);
        if (toplevel) {
            dw.expand();
        }
        return dw;
    }

    newFileWidget(entry: FileEntry, toplevel: boolean) {
        return new FileWidget(entry, this);
    }

    protected addEntryToNode(entryWidget: EntryWidget) {
        // We use a BR between widgets, but not before the first widget.
        if (this.rootNode.firstChild) {
            Dom.place(Dom.br(), this.rootNode);
        }
        Dom.place(entryWidget, this.rootNode);
    }

    /**
     * If entryWidget is selectable, not disabled, and not already selected, then this registers it
     * as selected within the picker and returns true. Otherwise, it returns false.
     */
    select(entryWidget: EntryWidget) {
        var entry = entryWidget.entry;
        if (entryWidget.selectable() && this.disabled.every((path) => path !== entry.fullPath)) {
            if (this.selectOne) {
                if (this.selectedWidgets[0]) {
                    this.selectedWidgets[0].deselect();
                }
            }
            if (this.selected.every((e) => e.fullPath !== entry.fullPath)) {
                this.selectedWidgets.push(entryWidget);
                this.selected.push(entry);
                this.onSelect(entry, this.selected);
                this.onChange(this.selected, this.destroying);
                return true;
            }
        }
        return false;
    }

    private selectFrom(dws: DirWidget[], paths: string[]): Promise<any> {
        return Promise.all(
            dws.map((dw) => {
                return paths.map((p) => {
                    var dwPath = dw.entry.fullPath;
                    if (p === dwPath) {
                        dw.select();
                    } else if (Str.startsWith(p, dwPath) && p[dwPath.length] === "/") {
                        return dw.expand().then(() => this.selectFrom(dw.dirs, paths));
                    }
                    return null;
                });
            }),
        );
    }

    /**
     * Calls SelectFileFromFiles if the beginning of the given path is found in one of the directories
     */
    private selectFileFromDirs(dws: DirWidget[], path: string) {
        dws.forEach((dw) => {
            const dwPath = dw.entry.fullPath;
            if (Str.startsWith(path, dwPath) && path[dwPath.length] === "/") {
                // Passes in this directory's subdirectories and files since if selectFileFromFiles
                // doesn't find the exact file match it will call selectFileFromDirs on the passed
                // in subdirectories.
                dw.expand().then(() => this.selectFromFiles(dw.dirs, dw.getFiles(), path));
                return;
            }
        });
    }

    /**
     * SelectFromFiles will select the specific file from fws if it exists. If not it will call
     * SelectFileFromDirs.
     */
    private selectFromFiles(dws: DirWidget[], fws: FileWidget[], path: string) {
        for (const fw of fws) {
            if (path === fw.entry.fullPath) {
                fw.select();
                return;
            }
        }
        this.selectFileFromDirs(dws, path);
    }

    private destroyToplevel() {
        this.toplevel.forEach((w) => {
            w.destroy();
        });
        this.toplevel.length = 0;
    }
}

abstract class EntryWidget {
    static CMP = { cmp: Cmp.forKey<string, EntryWidget>(Cmp.strCI, (e) => e.entry.name) };
    // whether this entry is selected in the Picker
    selected = false;
    // The outermost DOM node for this entry
    node: HTMLElement;
    // The node to which the click event is attached.
    protected entryNode: HTMLElement;
    protected entryTitle: HTMLElement;
    protected icon: Icon;
    private handle: on.Handle;
    private toDestroy: Util.Destroyable[] = [];

    constructor(
        entryClass: string,
        iconClass: string,
        public entry: Entry,
        protected picker: Picker,
    ) {
        this.icon = new Icon(iconClass);
        this.toDestroy.push(this.icon);
        this.entryTitle = Dom.span(
            { class: "picker-entry__name h-spaced-8" },
            this.icon.node,
            Dom.span(entry.name),
        );
        this.entryNode = Dom.div(
            { class: `picker-entry ${entryClass} h-spaced-8` },
            this.entryTitle,
        );
        this.node = Dom.div({ class: "picker-entry-container" }, this.entryNode);
        this.updateClickable();
    }

    /**
     * Handles a user clicking on the widget.
     */
    protected abstract onClick(): void;

    /**
     * Whether this.entryNode is clickable.
     */
    protected abstract clickable(): boolean;

    selectable() {
        return !!(
            this.picker.selectable & (this.entry.isFile ? Selectable.FILES : Selectable.DIRS)
        );
    }
    /**
     * If this widget is currently selectable in the picker (see Picker#select), then this method
     * selects it and returns true. Otherwise it returns false.
     */
    select() {
        if (this.picker.select(this)) {
            this.selected = true;
            Dom.addClass(this.entryTitle, "selected");
            return true;
        }
        return false;
    }
    deselect() {
        if (this.selected) {
            this.selected = false;
            Dom.removeClass(this.entryTitle, "selected");
            this.picker.deselect(this);
        }
    }
    destroy() {
        this.deselect();
        if (this.handle) {
            this.handle.remove();
        }
    }

    /**
     * Call this method when the result of this.clickable() may have changed.
     */
    protected updateClickable() {
        if (this.clickable()) {
            if (!this.handle) {
                this.handle = on(this.entryNode, Input.tap, (evt) => {
                    // e.g., when placed in a table's detail entry, we don't want the click to
                    // propagate up to the row, causing it to collapse when clicking on an entry.
                    evt.stopPropagation();
                    this.onClick();
                });
                Dom.addClass(this.entryNode, "action");
            }
        } else if (this.handle) {
            this.handle.remove();
            Dom.removeClass(this.entryNode, "action");
        }
    }
}

export class FileWidget extends EntryWidget {
    override entry: FileEntry;
    /**
     * See DirWidget for a better example of various states. This one only has three:
     *  1. Selectable, Unselected
     *  2. Selectable, Selected
     *  3. Unselectable, Unselected
     * The progressions are:
     *  Selectable -- 1 -> 2 -> 1
     *  Unselectable -- 3 -> 3
     */
    onClick() {
        if (this.selected) {
            // State 2; go to 1
            this.deselect();
        } else if (this.select()) {
            // We were in state 1; went to 2
        }
        // else State 3; stay here
    }

    constructor(entry: FileEntry, picker: Picker) {
        super("file-entry", "file", entry, picker);
    }

    protected clickable() {
        return this.selectable();
    }
}

export class DirWidget extends EntryWidget {
    override entry: DirectoryEntry;

    // These values are only meaningful in an `expand().then(() => {...})` callback.
    dirs: DirWidget[] = [];

    // In setNodeExpanded, the number of files that were not loaded due to this.picker.maxDirFiles.
    protected moreFiles = 0;
    private files: FileWidget[] = [];

    // When an expand is in progress, expanded is false and expanding is set. Otherwise, expanding
    // is not set, and expanded reflects the current state of the widget.
    private expanded = false;
    private expanding: Promise<void>;

    private triangle: Icon;

    constructor(entry: DirectoryEntry, picker: Picker) {
        super("dir-entry", "folder", entry, picker);
        this.triangle = new Icon("caret-right-20", { alt: "Expand folder" });
        Dom.place(this.triangle, this.entryNode, "first");
        this.close();
    }

    /**
     * What happens when you click on a directory depends on two properties: whether it's selectable
     * and whether it's expandable. It has two independent states: whether it's currently selected,
     * and whether it's currently expanded. The properties/states interact as follows:
     *
     *                  +-----------+
     *      States      | Selected? |
     *                  +-----+-----+
     *                  |  N  |  Y  |
     *  +-----------+---+-----+-----+
     *  |           | N |  1  |  2  |
     *  | Expanded? +---+-----+-----+
     *  |           | Y |  3  |  4  |
     *  +-----------+---+-----+-----+
     *
     *                    +------------------------------+
     *  State Transitions |         Selectable?          |
     *                    +--------------+---------------+
     *                    |       N      |       Y       |
     *  +-------------+---+--------------+---------------+
     *  |             | N | P1: 1        | P2: 1→2→1     |
     *  | Expandable? +---+--------------+---------------+
     *  |             | Y | P3: 1→3→1    | P4: 1→2→3→4→1 |
     *  +-------------+---+--------------+---------------+
     */
    onClick() {
        if (this.selected) {
            // State 2 or 4, P2 or P4. Either way, we need to deselect.
            this.deselect();
            if (this.expanded) {
                // State 4, P4: go to 1.
                this.close();
            } else if (this.expandable()) {
                // State 2, P4: go to 3.
                this.expand();
            } // else State 2, P2: we've already moved to 1.
        } else if (this.select()) {
            // We were in state 1 or 3, P2 or P4: we went to state 2 or 4. Either way, the Expanded
            // state remained the same and we just updated to Selected.
        } else if (this.expanded) {
            // State 3, P3: go to 1.
            this.close();
        } else if (this.expandable()) {
            // State 1, P3: go to 3.
            this.expand();
        } else {
            // State 1, P1: remain in 1.
        }
    }

    close() {
        if (this.expanding) {
            // An expand is currently in progress, so we have to close after it's finished.
            this.expanding.then(() => {
                this.close();
            });
        } else if (this.expanded) {
            this.setNodeClosed();
            this.destroyChildren();
            this.toggleOpenStyle(false);
            this.expanded = false;
        }
    }

    expand() {
        if (this.expanding) {
            return this.expanding;
        }
        if (this.expanded) {
            return Promise.resolve();
        }
        this.expanding = this.readEntries(this.entry.createReader()).then(
            () => {
                this.expanding = null;
                this.expanded = true;
                this.toggleOpenStyle(true);
                Arr.sort(this.dirs, EntryWidget.CMP);
                Arr.sort(this.files, EntryWidget.CMP);
                this.setNodeExpanded();
            },
            (e) => {
                Dialog.ok(
                    "Error",
                    "Error expanding " + this.entry.name + ": " + (e.message || e.name),
                );
                this.expanding = null;
                this.destroyChildren();
                return Promise.reject(e);
            },
        );
        return this.expanding;
    }

    readEntries(dirReader: DirectoryReader): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // DirectoryReader.readEntries may need to be called multiple times in order to list all
            // of the entries. It is done when the callback is invoked with a 0-length array.
            var withEntries = (entries: Entry[]) => {
                if (entries.length > 0) {
                    entries.forEach((e) => {
                        if (e.isDirectory) {
                            this.dirs.push(this.picker.newDirWidget(<DirectoryEntry>e, false));
                        } else if (e.isFile) {
                            if (this.files.length < this.picker.maxDirFiles) {
                                // display the file
                                this.files.push(this.picker.newFileWidget(<FileEntry>e, false));
                            } else {
                                // indicate that there are more files not being displayed
                                this.moreFiles++;
                            }
                        } else {
                            skipping(e);
                        }
                    });
                    // Iterate.
                    dirReader.readEntries(withEntries, reject);
                } else {
                    // Reading is complete
                    resolve();
                }
            };
            dirReader.readEntries(withEntries, reject);
        });
    }

    override destroy() {
        this.destroyChildren();
        super.destroy();
    }

    getFiles(): FileWidget[] {
        return this.files;
    }

    protected clickable() {
        return this.expandable() || this.selectable();
    }

    protected expandable() {
        return true;
    }

    protected setNodeExpanded() {
        const content = [];
        const children = this.children();
        if (children.length > 0) {
            content.push(this.children().map((ew) => ew.node));
        } else {
            content.push(Dom.div({ class: "picker-more" }, "(No files)"));
        }
        if (this.moreFiles) {
            content.push(
                Dom.div(
                    { class: "picker-more" },
                    `${Util.countOf(this.moreFiles, "more file")} not displayed`,
                ),
            );
        }
        Dom.place(Dom.div({ class: "picker-children" }, content), this.node);
    }

    protected setNodeClosed() {
        while (this.node.lastChild !== this.entryNode) {
            this.node.removeChild(this.node.lastChild);
        }
    }

    protected children() {
        return Arr.flat<EntryWidget>(this.dirs, this.files);
    }

    private toggleOpenStyle(open: boolean) {
        Dom.toggleClass(this.entryNode, "semi-bold", open);
        Dom.toggleClass(this.triangle.node, "icon_caret-down-20", open);
        Dom.toggleClass(this.triangle.node, "icon_caret-right-20", !open);
    }

    private destroyChildren() {
        this.children().forEach((w) => {
            w.destroy();
        });
        this.dirs.length = 0;
        this.files.length = 0;
        this.moreFiles = 0;
    }
}

function skipping(entry: Entry, msg?: String) {
    console.log(
        "The following " + (msg || "entry is neither a file nor a directory") + "; skipping.",
        entry,
    );
}

class VirtualDirectoryError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "VirtualDirectoryError";
    }
}

class VirtualReader implements DirectoryReader {
    private alreadyCalled: boolean;
    constructor(private entries: Entry[]) {
        this.alreadyCalled = false;
    }

    readEntries(callback: (entries: Entry[]) => void, onerror?: (evt: Error) => void): void {
        if (this.alreadyCalled) {
            callback([]);
            return;
        }
        this.alreadyCalled = true;
        callback(this.entries);
    }
}

type Flag = { create?: boolean; exclusive?: boolean };

/**
 * VirtualDirectory - This allows you to convert a list of files (e.g. from an `<input>` element)
 * into a properly recursive directory structure, as expected by the processed upload workflow.
 * Requires files to have `fullPath` set, which should work in Firefox / Chrome / Edge.
 */
export class VirtualDirectory implements DirectoryEntry {
    fullPath: string;
    isDirectory: boolean;
    isFile: boolean;
    name: string;
    private readonly fileMap: { [field: string]: FileEntry };
    private readonly fileNames: Set<string>; //All files in this dir and sub-dir. Only populated in root dir.
    private readonly directoryMap: { [field: string]: VirtualDirectory };
    private modificationTime: Date;
    private size: number;
    private rootIndex: number;

    constructor(name?: string, fullPath?: string) {
        this.fileNames = new Set();
        this.fileMap = {};
        this.directoryMap = {};
        this.isDirectory = true;
        this.isFile = false;
        this.size = 0;
        this.name = name;
        this.fullPath = fullPath;
        this.rootIndex = 1;
    }

    /**
     * Build a Virtual Directory for entries dropped into our upload tool. Subdirectories are
     * explored and all files and folders added.
     * @param entries The entries to be added to this virtual direcotry
     * @param replaceBase If true then the existing base directory for every entry is replaced,
     * otherwise the basePath is simply prepended.
     * @param allowDuplicates indicates if files with identical names should be added to directory
     */
    addEntries(entries: Entry[], replaceBase = false, allowDuplicates = false) {
        const basePath = this.fullPath;
        const dirWalks: Promise<void>[] = [];
        entries.forEach((entry) => {
            if (entry.isFile) {
                if (this.addFilename(<FileEntry>entry, allowDuplicates)) {
                    this.fileMap[entry.name] = <FileEntry>(
                        this.rebasedEntry(entry, basePath, replaceBase)
                    );
                }
            } else if (entry.isDirectory) {
                dirWalks.push(
                    this.loadDirEntries(
                        <DirectoryEntry>entry,
                        basePath,
                        replaceBase,
                        this,
                        allowDuplicates,
                    ),
                );
            }
        });
        return Promise.all(dirWalks);
    }

    /**
     * Returns true if filename is unique and is added, duplicates are ignored or allows based on flag
     */
    private addFilename(file: FileEntry, allowDuplicates: boolean): boolean {
        // Hidden files start with '.' do not add them.
        if ((!allowDuplicates && this.fileNames.has(file.name)) || file.name[0] === ".") {
            return false;
        }
        this.fileNames.add(file.name);
        return true;
    }

    /**
     * Every filename added is kept in a Set for the root directory. Allowing duplicate detection.
     */
    private loadDirEntries(
        dirEntry: DirectoryEntry,
        basePath: string,
        replace: boolean,
        root: VirtualDirectory,
        allowDuplicates: boolean,
    ) {
        // Do not explore hidden directories
        if (dirEntry.name[0] === ".") {
            return;
        }
        const dirWalk = new Walker({
            root: dirEntry,
            batchSize: 50,
            withFiles: (fileEntries, dirEntry) => {
                if (dirEntry.name[0] !== ".") {
                    const files = this.rebasedEntries(fileEntries, basePath, replace);
                    this.addFilesFromFullpaths(
                        files.filter((file) => root.addFilename(<FileEntry>file, allowDuplicates)),
                    );
                }
                return Promise.resolve();
            },
        });
        return dirWalk.walk();
    }

    /**
     * Build a virtual entry from existing entry. Ensures that every entry has basePath as its root.
     * @param entry    The entry to modify
     * @param basePath The root directory for the modified entry
     * @param replace  If true existing base directory is replaced, otherwise basePath is prepended.
     */
    private rebasedEntry(entry: Entry, basePath: string, replace: boolean) {
        let newPath = entry.fullPath;
        if (replace) {
            const segments = getSegments(newPath);
            segments[0] = basePath;
            newPath = segments.join("/");
        } else if (!Str.startsWith(entry.fullPath, basePath)) {
            newPath = basePath + entry.fullPath;
        }
        const newEntry = {
            isFile: entry.isFile,
            isDirectory: entry.isDirectory,
            name: entry.name,
            fullPath: newPath,
            getMetadata: entry.getMetadata ? entry.getMetadata.bind(entry) : null,
        };
        if (entry.isDirectory) {
            return newEntry;
        }
        if (entry.isFile) {
            const fileEntry = <FileEntry>newEntry;
            fileEntry.file = (<FileEntry>entry).file.bind(entry);
            return fileEntry;
        }
    }

    private rebasedEntries(entries: Entry[], basePath: string, replace: boolean) {
        return entries.map((entry) => this.rebasedEntry(entry, basePath, replace));
    }

    /**
     * Build Virtual Directory for array of entries which must have full paths included.
     */
    static createFromEntries(entries: Entry[], name?: string, fullPath?: string) {
        let directory = new VirtualDirectory(name, fullPath);
        directory.addFilesFromFullpaths(entries);
        return directory;
    }

    addFilesFromFullpaths(files: Entry[]) {
        if (files.length < 1) {
            return;
        }
        const firstFile = files[0];
        if (!this.name) {
            this.name = getSegments(firstFile.fullPath)[0];
        }
        if (!this.fullPath) {
            this.fullPath = "/" + this.name;
        }

        for (let file of files) {
            this.addFileFromSegments(
                file,
                getSegments(file.fullPath),
                this.rootIndex,
                this.addMetadata.bind(this),
            );
        }
    }

    protected addMetadata(md: Metadata) {
        this.size += md.size;
        if (!Is.defined(this.modificationTime) || md.modificationTime > this.modificationTime) {
            this.modificationTime = md.modificationTime;
        }
    }

    protected addFileFromSegments(
        file: Entry,
        segments: string[],
        index: number,
        cb: (md: Metadata) => void,
    ) {
        const addMetadataAndUpdateParent = (md: Metadata) => {
            this.addMetadata(md);
            this.getMetadata(cb);
        };

        const firstPart = segments[index];
        const rest = segments.slice(index + 1);

        if (rest.length > 0) {
            if (!(firstPart in this.directoryMap)) {
                const fullPath = "/" + segments.slice(0, index + 1).join("/");
                this.directoryMap[firstPart] = new VirtualDirectory(firstPart, fullPath);
            }
            this.directoryMap[firstPart].addFileFromSegments(
                file,
                segments,
                index + 1,
                addMetadataAndUpdateParent,
            );
            return;
        }
        if (!file.fullPath || file.fullPath[0] !== "/") {
            file.fullPath = "/" + file.fullPath;
        }
        this.fileMap[firstPart] = file as FileEntry;
        file.getMetadata && file.getMetadata(addMetadataAndUpdateParent);
    }

    getDirectory(
        path: string,
        flag?: Flag,
        success?: (entry: DirectoryEntry) => void,
        error?: (evt: Error) => void,
    ) {
        this.getEntry(
            getSegments(path),
            this.rootIndex,
            flag,
            (entry) => {
                if (entry.isFile) {
                    error(new VirtualDirectoryError("This is a file, not a directory"));
                    return;
                }
                success(entry as DirectoryEntry);
            },
            error,
        );
    }

    getFile(
        path: string,
        flag?: Flag,
        success?: (entry: FileEntry) => void,
        error?: (evt: Error) => void,
    ) {
        this.getEntry(
            getSegments(path),
            this.rootIndex,
            flag,
            (entry) => {
                if (entry.isDirectory) {
                    error(new VirtualDirectoryError("This is a directory, not a file"));
                    return;
                }
                success(entry as FileEntry);
            },
            error,
        );
    }

    protected getEntry(
        segments: string[],
        index: number,
        flag?: Flag,
        success?: (entry: Entry) => void,
        error?: (evt: Error) => void,
    ) {
        const firstPart = segments[index];
        const rest = segments.slice(index + 1);
        if (rest.length > 0) {
            if (!(firstPart in this.directoryMap)) {
                error(new VirtualDirectoryError(`Directory does not exist: ${firstPart}`));
                return;
            }
            this.directoryMap[firstPart].getEntry(segments, index + 1, flag, success, error);
        } else {
            if (firstPart in this.directoryMap) {
                success(this.directoryMap[firstPart]);
                return;
            } else if (firstPart in this.fileMap) {
                success(this.fileMap[firstPart]);
            } else {
                error(new VirtualDirectoryError(`Entry does not exist: ${firstPart}`));
            }
        }
    }

    createReader(): DirectoryReader {
        const children: Entry[] = [];
        for (let key of Object.keys(this.directoryMap)) {
            children.push(this.directoryMap[key]);
        }
        for (let key of Object.keys(this.fileMap)) {
            children.push(this.fileMap[key]);
        }
        return new VirtualReader(children);
    }

    getMetadata(callback: (md: Metadata) => void, onError?: (evt: Error) => void) {
        callback({
            modificationTime: this.modificationTime,
            size: this.size,
        });
    }
}

/**
 * DROP_DIR_NAME is the name used for entries and are dragged and dropped for uploads. These entries
 * do not have knowledge of the "parent" directory, or possibly have different parents.
 * Colons are used in the name since they are not allowed characters in Windows/Mac OS so this dir
 * cannot exist for most user's
 */
export const DROP_DIR_NAME = "Dropped:Files:Virtual:Directory";
export function createVirtualDroppedDir(): VirtualDirectory {
    return new VirtualDirectory(DROP_DIR_NAME, "/" + DROP_DIR_NAME);
}
