import { Color } from "Everlaw/ColorUtil";
import Base = require("Everlaw/Base");
import Arr = require("Everlaw/Core/Arr");
import Rest = require("Everlaw/Rest");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import User = require("Everlaw/User");
import UserObject = require("Everlaw/UserObject");
import Util = require("Everlaw/Util");
import { ColorTokens } from "design-system";
import { batchFetcher, cachedFetcher } from "Everlaw/BatchFetcherUtils";
import { DateDisplayFormat } from "Everlaw/DateUtil";
import { elevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";
import { parseUTC } from "Everlaw/ProjectDateUtil";

class Binder extends UserObject implements Base.Colored {
    get className() {
        return "Binder";
    }
    override id: Binder.Id;
    name: string;
    userId: User.Id;

    // A cache of asynchronously fetched counts.
    // Querying this cache may trigger requests to the server.
    static countCache = cachedFetcher(batchFetcher(getCountsById));

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    rename(name: string): Promise<string> {
        return Rest.post("binders/rename.rest", {
            id: this.id,
            name,
        }).then((data) => {
            this._mixin(data);
            Base.publish(this);
            return this.name;
        });
    }
    override compare(other: Binder): number {
        // First compare lowercased names so that we get A, a, B, b, C, c...
        // instead of A, B, C, ... a, b, c...
        let disp = this.name.toLowerCase().localeCompare(other.name.toLowerCase());
        if (disp !== 0) {
            return disp;
        }
        // If lowercased names are the same, compare names without lowercasing.
        disp = this.name.localeCompare(other.name);
        if (disp !== 0) {
            return disp;
        }
        // If names are the same, move on to comparing users.
        const thisUser = this.userId && Base.get(User, this.userId);
        const otherUser = other.userId && Base.get(User, other.userId);
        if (thisUser && otherUser) {
            // Compare users, if they both have one.
            disp = thisUser.name.toLowerCase().localeCompare(otherUser.name.toLowerCase());
            if (disp !== 0) {
                return disp;
            }
        } else if (thisUser || otherUser) {
            // Only one user is unknown - put that one last.
            return thisUser ? -1 : 1;
        }
        // Both users are the same or both are unknown. Compare by id.
        return this.id - other.id;
    }
    getShortName() {
        const name = this.unPrefixedName();
        const date = this.getDate();
        return date ? name.substring(date.length) : name;
    }
    getCreated() {
        const date = this.getDate();
        return date ? parseUTC(date, "/", DateDisplayFormat.YMD).millis : 0;
    }
    override defaultLastActivity() {
        return this.getCreated();
    }
    private getDate(): string | null {
        if (!this.isImport()) {
            return null;
        }
        const m = this.unPrefixedName().match(/^[0-9]{4}\/[0-9]{2}\/[0-9]{2}/);
        return m && m[0];
    }
    private unPrefixedName() {
        if (this.isIndicatorBinder(Binder.IMPORT_PREFIX)) {
            return this.name.substring(Binder.IMPORT_PREFIX.length);
        }
        return this.name;
    }
    getSearchTerm() {
        return "tagged";
    }
    isImport() {
        return this.isIndicatorBinder(Binder.IMPORT_PREFIX);
    }
    private isIndicatorBinder(prefix: string) {
        return this.name.indexOf(prefix) === 0 && User.isSuperuser(this.userId);
    }
    override display() {
        const user = this.getUser();
        return this.name + (user && !user.equals(User.me) ? " (" + user.display() + ")" : "");
    }
    getColor() {
        if (this.isImport()) {
            return Binder.IMPORT_COLOR;
        }
        return Binder.COLOR;
    }
    getUser() {
        return Base.get(User, this.userId);
    }
    delete(callback?: (b: Binder) => void, error?: Rest.Callback) {
        const performDelete = () => {
            Rest.post("binders/delete.rest", { id: this.id }).then(
                () => {
                    Base.remove(this);
                    if (callback) {
                        callback(this);
                    }
                },
                (e) => {
                    error && error(e);
                    throw e;
                },
            );
            return true;
        };
        import("Everlaw/Property").then((Property) => {
            Rest.post("search/searchInfo.rest", {
                eql: new Property.Tag(this).toString(),
            }).then(
                (sr) => {
                    const n = sr.numResults;
                    const count = parseInt(n);
                    const prompt =
                        count <= 0
                            ? ""
                            : "Are you sure you want to delete this binder? It will be removed from "
                              + Util.countOf(count, "document")
                              + ".";
                    if (prompt) {
                        QueryDialog.create({
                            title: "Delete binder confirmation",
                            prompt: prompt,
                            submitIsSafe: false,
                            submitText: "Delete",
                            onSubmit: performDelete,
                        });
                    } else {
                        performDelete();
                    }
                },
                (e) => {
                    error && error(e);
                    throw e;
                },
            );
        });
    }
    @elevatedRoleConfirm("creating a binder")
    static create(name: string) {
        return Rest.post("binders/create.rest", { name }).then((data) => Base.set(Binder, data));
    }
}

// Values may be null if the user does not have access to the binder for some reason.
function getCountsById(binders: Binder[]): Promise<(number | null)[]> {
    const ids = binders.map((b) => b.id);
    return Rest.post("binders/counts.rest", { ids });
}

module Binder {
    export type Id = number & Base.Id<"Binder">;

    export const COLOR = Color.fromEverColor(ColorTokens.OBJECT_BINDER);
    export const IMPORT_COLOR = Color.fromEverColor(ColorTokens.OBJECT_UPLOAD_IMPORT);

    export const IMPORT_PREFIX = "Import: ";

    export function getBinderList(permission?: string, override?: User.Override) {
        return Arr.sort(
            Base.get(Binder).filter((b: Binder) => {
                return !permission || User.me.can(permission, b, override);
            }),
        );
    }
}

export = Binder;
