import C = require("Everlaw/Constants");
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");

var longPollURL: string;
var pageMuxId: string;

if (JSP_PARAMS.Multiplex) {
    // Long polling doesn't work on non-standard hosts.
    const host =
        window.location.hostname.indexOf("everlaw") < 0 ? "" : JSP_PARAMS.Multiplex.longPollHost;
    longPollURL = host + JSP_PARAMS.Multiplex.longPollURL;
    pageMuxId = JSP_PARAMS.Multiplex.muxId;
}

var handlerTable: {
    [subId: string]: {
        callback: (msg: any) => void;
        reconnect?: () => void;
    };
} = {};
var openSubIds: { [subId: string]: boolean } = {};
var ongoing: XMLHttpRequest;
var recreating = false;
// Used to display status messages if we are expecting downtime.
export let pollFailed = false;

function poll(seq: number, recreateNow = true) {
    // Find all subscription IDs that are open on the server but closed on the client
    var needClose = Object.keys(openSubIds).filter(function (subId) {
        return !handlerTable[subId];
    });
    ongoing = Rest.longPoll({
        url: longPollURL,
        content: {
            userId: User.me.id,
            muxId: pageMuxId,
            seq: seq,
            close: needClose,
        },
        onError: function () {
            ongoing = null;
            pollFailed = true;
        },
        onResponse: function (r) {
            ongoing = null;
            pollFailed = false;
            if (r.success) {
                needClose.forEach(function (subId) {
                    delete openSubIds[subId];
                });
                r.data.forEach(function (item: any) {
                    if (item.open) {
                        openSubIds[item.subId] = true;
                    } else {
                        var handler = handlerTable[item.subId];
                        handler && handler.callback(item.value);
                    }
                });
                poll(seq + r.data.length);
            } else if (r.data === true) {
                recreating = true;
                if (recreateNow) {
                    // The server no longer has our mux; either we disconnected for too long, or the
                    // server restarted. Try to recreate our connection.
                    recreateMux();
                } else {
                    // Avoid a tight loop with recreateMux in case of error.
                    setTimeout(recreateMux, C.MIN);
                }
            } // else we have an unrecoverable logic error
        },
    });
}

export function pause() {
    if (ongoing) {
        Rest.longPollStop(ongoing);
        ongoing = null;
        return true;
    }
    return false;
}

export function resume() {
    if (!ongoing && !recreating) {
        recreateMux();
    }
}

function recreateMux() {
    console.log("Attempting to recreate the mux...");
    recreating = true;
    Rest.post(JSP_PARAMS.Multiplex.recreateURL).then(
        (muxId: string) => {
            recreating = false;
            pageMuxId = muxId;
            openSubIds = {};
            for (var subId in handlerTable) {
                var handler = handlerTable[subId];
                delete handlerTable[subId];
                if (handler.reconnect) {
                    handler.reconnect();
                }
            }
            poll(0, false);
        },
        (data: Rest.Failed) => {
            // If the user has a poor connection, or if they are logged out, we won't be able to
            // recreate the connection. Avoid alerting the user; just try again after a minute.
            console.log(data.message);
            setTimeout(recreateMux, C.MIN);
        },
    );
}

export function initialize(defaultSubs: { [id: string]: (msg: any) => void }) {
    Object.entries(defaultSubs).forEach(([subId, callback]) => {
        const handler = {
            callback: callback,
            reconnect() {
                // The server has already reestablished these connections, so just stick them
                // immediately back into the handler table.
                handlerTable[subId] = handler;
                console.log("Restored default subscription", subId);
            },
        };
        handlerTable[subId] = handler;
    });
    poll(0);
}

var nextSubscriptionId = 0;

/**
 * An object to manage the lifecycle of a dynamically-created subscription:
 *
 * 1. Create a new Subscription object. The constructor allocates a new subscription ID.
 * 2. Make a request to the server that will create the server-side subscription with that ID.
 *    - We may not be ready to handle notifications immediately; typically, because they represent
 *      changes to some object fetched by the request, which can't be applied until we receive
 *      the object. If notifications are received early, the Subscription object will queue them up
 *      until .begin() is called, so that no changes are lost for this use case.
 * 3. When ready to start handling notifications, call .begin() with the handler function.
 *    - Any queued notifications will be handled immediately.
 * 4. When the subscription is no longer needed, call .close().
 *    - Note: Even if the request fails, this is still necessary to avoid a memory leak.
 *
 * To reduce the amount of boilerplate code involved, instead of creating this object directly, use
 * the Multiplex.subscribe function defined below.
 */
export class Subscription {
    id = nextSubscriptionId++;
    private queue: any[];
    _register() {
        this.queue = [];
        handlerTable[this.id] = {
            callback: (msg) => {
                this.queue.push(msg);
            },
        };
    }
    /**
     * Asynchronously executes the given callback on each message that was received since the
     * connection was established, and ensures that the same callback is executed for each message
     * received thereafter, maintaining the order of events as they occurred on the server.
     *
     * The reconnect callback provides a way to resume a subscription after the connection is lost.
     * If the server restarts, or if the client remains disconnected for too long, the mux is
     * expired and all subscriptions are lost. There's not a general way to restore the connection
     * while maintaining data consistency, as the events between disconnecting and reconnecting are
     * lost.
     *
     * Subscribers can implement reconnect to restore the connection appropriately for their use
     * case, typically by perform a fetch-and-subscribe action; Multiplex.resubscribe returns a
     * reconnect function that calls Multiplex.subscribe again with the same parameters. If
     * reconnect is not implemented, the subscription is closed.
     */
    begin(callback: (msg: any) => void, reconnect?: () => void) {
        var handler = handlerTable[this.id];
        if (!handler) {
            throw Error(".begin() called on closed subscription");
        }
        if (!this.queue) {
            throw Error(".begin() called more than once on the same subscription");
        }
        handler.reconnect = reconnect;
        setTimeout(() => {
            // For consistency, always call the callback asynchronously
            if (handlerTable[this.id] === handler) {
                // Make sure we weren't closed or restarted
                this.queue.forEach(callback);
                this.queue = null;
                handler.callback = callback;
            }
        });
    }
    close() {
        this.queue = null;
        delete handlerTable[this.id];
    }

    isClosed(): boolean {
        return !handlerTable[this.id];
    }
}

export function resubscribe(params: SubscribeParams, sub: Subscription) {
    return function () {
        console.log("Reconnecting to:", params.url);
        connect(params, sub);
    };
}

export interface SubscribeParams {
    /**
     * A subscription URL (a .rest which takes at minimum muxId and subId as request parameters, and
     * creates the subscription on the server side)
     */
    url: string;
    /**
     * Any additional request parameters besides muxId and subId
     */
    content?: any;
    method?: string;
    /**
     * Called when the request is successful. The params are this value.
     */
    success?: (sub: Subscription, data: any, params: SubscribeParams) => void;
    /**
     * Called on error; the subscription is closed automatically before this callback fires.
     */
    error?: Rest.Callback;
    errorMessage?: (msg: string) => void;
}

/**
 * Convenience function for creating a new subscription. Returns the subscription object (for the
 * old-style subscriptions where the request doesn't return any data, it's easier to have the
 * subscription object available immediately rather than getting it in a success callback).
 */
export function subscribe(params: SubscribeParams) {
    var sub = new Subscription();
    connect(params, sub);
    return sub;
}

function connect(params: SubscribeParams, sub: Subscription) {
    sub._register();
    params = {
        ...params,
        content: {
            ...params.content,
            muxId: pageMuxId,
            subId: sub.id,
        },
    };
    const success = (data: any) => {
        params.success && params.success(sub, data, params);
    };
    const error = (e) => {
        sub.close();
        params.error && params.error(e);
        if (params.errorMessage) {
            params.errorMessage(e.message);
        } else {
            throw e;
        }
    };
    if (params.method && params.method.toLowerCase() === "get") {
        Rest.get(params.url, params.content).then(success, error);
    } else {
        Rest.post(params.url, params.content).then(success, error);
    }
}
