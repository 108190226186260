import Dom = require("Everlaw/Dom");
import ActionNode = require("Everlaw/UI/ActionNode");
import Util = require("Everlaw/Util");
import { clsx } from "clsx";
import { EverId } from "Everlaw/EverAttribute/EverId";
import {
    RecommendationOverlay,
    RecommendationOverlayProps,
} from "Everlaw/SmartOnboarding/Components/RecommendationOverlay";
import type RecommendationStep from "Everlaw/SmartOnboarding/RecommendationStep";
import { waitFor } from "Everlaw/SmartOnboarding/RecommendationUtil";
import { PopoverPlacement } from "design-system";
import { ReactWidget, wrapReactComponent } from "Everlaw/UI/ReactWidget";
import { ReactNode } from "react";

/**
 * This class helps create chains of overlays with a progress bar, so that we can lead users in
 * workflows that they might be unfamiliar with. It is just a UI class that doesn't handle things
 * like permissions. For that, refer to Recommendation.ts.
 */

const DISPLAY_OVER_DIALOG_CLASS = "recommendation-overlay--over-dialog-or-popover";

interface ChainLink {
    overlay: Promise<ReactWidget<RecommendationOverlayProps>> | null;
    baseNode: EverId | null;
    toastLocation: { x?: number; y?: number } | null;
    placement: PopoverPlacement[] | null;
    recStep: RecommendationStep | null;
    displayOverDialogOrPopover: boolean | null;
    isWalkMeTutorial: boolean;
    shouldAddActionNode: boolean;
    hideBackButton: boolean;
    shouldSkip: () => boolean;
}
function defaultChainLink(): ChainLink {
    return {
        overlay: null,
        baseNode: null,
        toastLocation: null,
        placement: null,
        recStep: null,
        displayOverDialogOrPopover: null,
        isWalkMeTutorial: false,
        shouldAddActionNode: true,
        hideBackButton: false,
        shouldSkip: () => false,
    };
}

export default class RecommendationChain {
    chain: ChainLink[];
    currentIdx = -1;
    private toDestroy: Util.Destroyable[] = [];
    constructor(private messages: ReactNode[]) {
        this.chain = messages.map(() => defaultChainLink());
    }
    private canAccessOverlay(): boolean {
        return (
            this.currentIdx >= 0
            && this.currentIdx < this.chain.length
            && this.hasBeenRegistered(this.currentIdx)
        );
    }
    private async updateCurrentOverlay(show: boolean): Promise<void> {
        if (this.canAccessOverlay()) {
            const overlayOrNull = this.getOverlay(this.currentIdx);
            if (!overlayOrNull) {
                return;
            }
            const overlay = await overlayOrNull;
            // Check if we've unregistered the previously awaited overlay, and update props only
            // if we have an overlay to update.
            if (overlay.isDestroyed()) {
                return;
            }
            overlay.updateProps({ show });
        }
    }
    private async openCurrent(): Promise<void> {
        return this.updateCurrentOverlay(true);
    }
    private async closeCurrent(): Promise<void> {
        return this.updateCurrentOverlay(false);
    }
    registerNode(
        stepNumber: number,
        recStep: RecommendationStep,
        placement?: PopoverPlacement[],
        baseNode: EverId | null = null,
        isWalkMeTutorial?: boolean,
        shouldAddActionNode?: boolean,
        shouldSkip?: () => boolean,
        hideBackButton?: boolean,
        toastLocation?: { x?: number; y?: number },
        displayOverDialogOrPopover?: boolean,
    ): void {
        if (this.hasBeenRegistered(stepNumber)) {
            throw new Error("already registered a node for this step");
        }
        this.chain[stepNumber].baseNode = baseNode;
        this.chain[stepNumber].placement = placement || null;
        if (recStep) {
            this.chain[stepNumber].recStep = recStep;
        }
        if (shouldAddActionNode === false) {
            this.chain[stepNumber].shouldAddActionNode = false;
        }
        if (hideBackButton === true) {
            this.chain[stepNumber].hideBackButton = true;
        }
        if (shouldSkip) {
            this.chain[stepNumber].shouldSkip = shouldSkip;
        }
        if (isWalkMeTutorial) {
            this.chain[stepNumber].isWalkMeTutorial = isWalkMeTutorial;
        }
        if (toastLocation) {
            this.chain[stepNumber].toastLocation = toastLocation;
        }
        if (displayOverDialogOrPopover !== undefined) {
            this.chain[stepNumber].displayOverDialogOrPopover = displayOverDialogOrPopover;
        }
    }
    unregisterNode(stepNumber: number): void {
        this.chain[stepNumber].overlay?.then((overlay) => overlay.destroy());
        this.chain[stepNumber].baseNode = null;
        this.chain[stepNumber].placement = null;
        this.chain[stepNumber].recStep = null;
        this.chain[stepNumber].overlay = null;
    }
    private targetElementInDialogOrPopup(targetElement: Element): boolean {
        const targetInDialog = !!targetElement.closest("[role='dialog']");
        const targetInDojoPopover = !!targetElement.closest(".dijitPopup");
        return targetInDialog || targetInDojoPopover;
    }
    private async makeOverlay(
        stepNumber: number,
    ): Promise<ReactWidget<RecommendationOverlayProps>> {
        const {
            baseNode,
            toastLocation,
            placement,
            shouldAddActionNode,
            hideBackButton,
            recStep,
            isWalkMeTutorial,
            displayOverDialogOrPopover,
        } = this.chain[stepNumber];
        if (!recStep) {
            throw new Error("Expected recommendation step to be defined.");
        }
        const overlayProps: RecommendationOverlayProps = {
            show: false,
            isWalkMeTutorial,
            progress: {
                currentStep: stepNumber,
                totalSteps: this.chain.length,
            },
            hideBackButton,
            onPrev: () => recStep.activatePreviousStep(),
            onNext: () => recStep.activateNextStep(),
            onDismiss: () => {
                recStep.onDismiss();
                recStep.parent.dismiss();
                this.destroy();
            },
            children: this.messages[stepNumber],
        };
        if (baseNode) {
            const targetElement = await waitFor(baseNode, recStep.parent);
            const shouldDisplayOverDialog =
                displayOverDialogOrPopover === null
                    ? this.targetElementInDialogOrPopup(targetElement)
                    : displayOverDialogOrPopover;
            Object.assign(overlayProps, {
                target: { current: targetElement },
                placement: placement || undefined,
                className: clsx({
                    [DISPLAY_OVER_DIALOG_CLASS]: shouldDisplayOverDialog,
                }),
            });
            // Advance the recommendation if the popover's target is clicked.
            const targetClickHandler = () => {
                if (stepNumber < this.chain.length - 1) {
                    recStep.activateNextStep();
                } else {
                    recStep.onDismiss();
                    this.destroy();
                }
            };
            if (shouldAddActionNode) {
                // Use ActionNode instead of directly setting event listeners to support Dojo
                // widgets that use Dojo's event system. We will eventually want to move away from
                // this.
                this.toDestroy.push(
                    new ActionNode(targetElement as HTMLElement, {
                        onClick: targetClickHandler,
                        usePress: true,
                    }),
                );
            }
        } else {
            Object.assign(overlayProps, {
                toastLocation: toastLocation ?? undefined,
                className: clsx({ [DISPLAY_OVER_DIALOG_CLASS]: displayOverDialogOrPopover }),
            });
        }
        const newOverlay: ReactWidget<RecommendationOverlayProps> = wrapReactComponent(
            RecommendationOverlay,
            overlayProps,
        );
        this.toDestroy.push(newOverlay);
        return newOverlay;
    }
    getOverlay(stepNumber: number): Promise<ReactWidget<RecommendationOverlayProps>> | null {
        const shouldSkip = this.chain[stepNumber].shouldSkip?.();
        if (shouldSkip) {
            return null;
        }
        const overlay = this.chain[stepNumber].overlay;
        if (overlay) {
            return overlay;
        }
        this.chain[stepNumber].overlay = this.makeOverlay(stepNumber);
        return this.chain[stepNumber].overlay;
    }
    hasBeenRegistered(stepNumber: number): boolean {
        return !!this.chain[stepNumber].recStep;
    }
    destroy(): void {
        this.closeCurrent().then(() => {
            for (let stepNumber = 0; stepNumber < this.chain.length; stepNumber++) {
                this.chain[stepNumber].overlay?.then((o) => o.destroy());
                this.chain[stepNumber].overlay = null;
            }
            this.currentIdx = -1;
            Util.destroy(this.toDestroy);
        });
    }
    /*
     * This function is called when some trigger occurs that causes the next overlay to be shown.
     * Note that it will not do anything if the node for that particular step hasn't been
     * registered. It will not remember being called and will have to be called again after
     * registerNode if the overlay needs to be shown. This was a deliberate design decision so that
     * this class easily integrates with RecommendationStepInterface.
     */
    async activate(value = -1): Promise<void> {
        await this.closeCurrent();
        this.currentIdx = value >= 0 ? value : this.currentIdx + 1;
        await this.openCurrent();
    }
    closeIfExists(stepNumber: number): void {
        if (stepNumber < this.chain.length) {
            const overlay = this.chain[stepNumber].overlay;
            overlay?.then((o) => o.updateProps({ show: false }));
        }
    }
}

/*
 * This interface defines the parameters for constructing one step in an overlay chain.
 * The reason the constructor takes in () => Dom.Nodeable instead of Dom.Nodeable is for the
 * case when the base node does not exist during initialization.
 */
export interface Params {
    node: () => Dom.Nodeable;
    msg: Dom.Nodeable;
    orient: string[];
}
