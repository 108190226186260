/**
 * Must match PrefixParsingLevel.java
 *
 * @author Jason Lee
 */
enum PrefixParsingLevel {
    NO_EMPTY_OR_AMBIGUOUS_PREFIX = "NO_EMPTY_OR_AMBIGUOUS_PREFIX",
    NO_EMPTY_PREFIX = "NO_EMPTY_PREFIX",
    ALLOW_EMPTY_PREFIX = "ALLOW_EMPTY_PREFIX",
}

export = PrefixParsingLevel;
