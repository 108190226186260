import * as Base from "Everlaw/Base";

export abstract class MultiMatterObject extends Base.Object {
    type?: string;
    areaOfLaw?: string;
    description?: string;
    created: number;

    constructor(params: MultiMatterObject) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: MultiMatterObject): void {
        Object.assign(this, params);
        // Need to explicitly assign values here because Object.assign() does not assign undefined values.
        this.type = params.type;
        this.areaOfLaw = params.areaOfLaw;
        this.description = params.description;
    }
}
