import Button = require("Everlaw/UI/Button");
import ColumnCombination_type = require("Everlaw/Upload/Metadata/ColumnCombination");
import Dom = require("Everlaw/Dom");
import E = require("Everlaw/Entities");
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import MetadataDefinition_type = require("Everlaw/Upload/Metadata/MetadataDefinition");
import Project = require("Everlaw/Project");
import TabView = require("Everlaw/Upload/Util/TabView");
import Task = require("Everlaw/Task");
import ToggleButton = require("Everlaw/UI/ToggleButton");
import Type = require("Everlaw/Type");
import TypeFormat = require("Everlaw/Upload/Metadata/TypeFormat");
import dojo_on = require("dojo/on");

const NARROW_COLUMN_NUM = 4;

interface TypeFormatOption {
    click: dojo_on.Handle;
    node: HTMLElement;
    icon: Icon;
    typeFormat: TypeFormat;
}

/**
 * Corresponds to LoadfileColumn.java.
 */
class LoadfileColumn extends TabView.Tabbable {
    header: string;
    selectedTypeFormat: TypeFormat;
    private ignoreErrors: { [typeFormatId: string]: boolean } = {};
    private hasSamples = false;

    private _tabTypeName: HTMLElement;
    private _content: HTMLElement;
    private optionsNode: HTMLElement;
    private optionsByTf: { [typeFormatId: string]: TypeFormatOption };
    private errorActions: HTMLElement;
    private _ignoreErrorsButton: Button;
    private formatInfo: HTMLElement;
    private unexpectedTypeWarning: HTMLElement;

    /**
     * To make a "floating" column don't provide `columnCombination`.
     */
    constructor(
        params: any,
        private def: MetadataDefinition_type,
        public columnCombination?: ColumnCombination_type,
    ) {
        super();
        this.id = this.header = params.header;
        if (params.selectedTaId) {
            const id = TypeFormat.idFromJson(params.selectedTaId);
            this.selectTypeFormat(id);
        } else {
            this.selectBestTypeFormat();
        }
        this.ignoreErrors[this.selectedTypeFormat.id] = params.ignoreErrors;
    }
    toJSON() {
        return {
            header: this.header,
            selectedTaId: this.selectedTypeFormat.idJSON(),
            // Only store the ignoreError status for the currently selected TypeFormat.
            ignoreErrors: this.ignoringErrors(),
        };
    }
    analysis() {
        return this.def.analyses.columnAnalysis(this.header);
    }
    selectedNameAnalyses() {
        return this.analysis().getNameAnalyses(this.selectedTypeFormat.id);
    }
    canCombine() {
        return !this.isCombined() && !this.ignored();
    }
    isCombined() {
        return this.columnCombination && this.columnCombination.isCombined();
    }
    ignored() {
        return this.def.ignored[this.header] === this;
    }
    type() {
        return this.selectedTypeFormat.type;
    }

    errors() {
        return this.selectedTypeFormat.numErrors > 0 && !this.ignoringErrors();
    }
    /**
     * Returns true iff this column's currently selected type looks suspicious; i.e., the user
     * should take a closer look at it. Currently, if there is an unselected, non-Text type format.
     * NOTE: For this purpose "Text" also means "viewable as text", i.e. AddressList/From.
     */
    suspicious() {
        const selected = this.selectedTypeFormat;
        return (
            selected.suspicious
            || this.unexpectedTypeSelected()
            || this.analysis().someTypeFormat(
                (other) => other !== selected && !other.type.alwaysParses,
            )
        );
    }
    private unexpectedTypeSelected() {
        return !this.analysis().isExpectedType(this.selectedTypeFormat);
    }

    selectBestTypeFormat() {
        this.selectTypeFormat(this.analysis().getBestTypeFormatId());
    }
    selectTypeFormat(formatId: string) {
        this.selectedTypeFormat = this.analysis().getTypeFormat(formatId);
        this.updateTypeDisplay();
    }
    sampleResults() {
        return Object.values(this.selectedTypeFormat.sampleValues || {});
    }
    ignoringErrors(tfId: string = this.selectedTypeFormat.id) {
        return this.ignoreErrors[tfId] || false;
    }

    /* Type Stage UI */

    override init() {
        super.init();
        this.updatePane();
        this.updateTypeDisplay();
    }
    /**
     * Creates the unchanging elements of the displayed tab of this column.
     */
    protected initTab() {
        Dom.place(
            [
                Dom.div({ class: "metadata-tab-title ellipsed" }, this.header),
                (this._tabTypeName = Dom.div()),
            ],
            this._tab,
        );
    }

    /**
     * Creates the unchanging elements of the displayed pane of this column.
     */
    protected initPane() {
        const formatInfoIcon = new Icon("info-circle-20");
        this.toDestroy.push(formatInfoIcon);
        this.formatInfo = Dom.div(
            { class: "h-spaced-8" },
            formatInfoIcon.node,
            Dom.span(`To change ${Type.DATE_TIME.displayName()} formats, use the gear icon above`),
        );

        const warningIcon = new Icon("alert-triangle-20");
        this.toDestroy.push(warningIcon);
        this.unexpectedTypeWarning = Dom.div(
            { class: "upload-inline-warning-banner" },
            warningIcon.node,
            Dom.span("Load file headers like"),
            Dom.span({ class: "semi-bold" }, this.header),
            Dom.span("generally do not use this metadata type"),
        );

        const ignoredToggle = new ToggleButton({
            state: this.ignored(),
            offLabel: "Ignore field",
            onLabel: "Include field",
            buttonParams: {
                class: "skinny",
            },
            onChange: (state: boolean, me: ToggleButton) => {
                this.def.ignoreColumn(this, state);
            },
        });
        this.toDestroy.push(ignoredToggle);

        this._ignoreErrorsButton = new Button({
            label: "Ignore errors",
            class: "skinny safe",
            width: "100%",
            onClick: () => {
                this.ignoreErrors[this.selectedTypeFormat.id] = true;
                this.def.updateValidity();
                this.updatePane();
                this.updateTab();
            },
        });
        this.toDestroy.push(this._ignoreErrorsButton);
        const downloadErrorsButton = new Button({
            label: "Download errors",
            class: "skinny safe",
            width: "100%",
            onClick: () => {
                // If in a project-level page, use the current project for permission checks.
                // Else, the user must be an org admin.
                Task.createTask(`/parcel/${this.def.upload.parcel}/tasks/downloadTypeErrors.rest`, {
                    uploadId: this.def.upload.id,
                    typeAnalysisId: JSON.stringify(this.selectedTypeFormat.idJSON()),
                    formats: this.selectedTypeFormat.formats,
                    projectId: Project.CURRENT?.id,
                });
            },
        });
        this.toDestroy.push(downloadErrorsButton);
        this.errorActions = Dom.div(
            { class: "metadata-type-option__error-actions v-spaced-4" },
            this._ignoreErrorsButton.node,
            downloadErrorsButton.node,
        );

        this._content = Dom.div({ class: "metadata-pane__options" });
        const paneContent = [
            Dom.div(
                { class: "metadata-pane__title" },
                Dom.h6(this.header),
                ignoredToggle.getNode(),
            ),
            this.formatInfo,
            this.unexpectedTypeWarning,
            this._content,
        ];
        Dom.place(paneContent, this._pane);
    }

    /**
     * The information in the tab is affected by the selectedFormat and
     * its number of errors, the number of column formats, and whether the user
     * has inspected the column, so this method should be called after a
     * change in any of those states.
     */
    override updateTab() {
        super.updateTab();
        if (this._tabTypeName) {
            const tabType = this.ignored()
                ? Dom.div({ class: "italic" }, "Ignored field")
                : this.selectedTypeFormat.displaySummary();
            Dom.setContent(this._tabTypeName, tabType);
        }
        if (this.optionsNode) {
            Dom.toggleClass(this.optionsNode, "metadata-def-options--ignored", this.ignored());
        }
    }

    protected getTabClass() {
        if (this.ignored()) {
            return "ignored";
        } else if (this.errors()) {
            return "resolution";
        } else if (this.suspicious()) {
            return "inspection";
        }
        return "verification";
    }

    /**
     * Updates the data in this._table after a change to this.formats.
     */
    updatePane() {
        // Check that the pane has been initialized.
        if (this._content) {
            Dom.empty(this._content);
            if (this.optionsByTf) {
                Object.values(this.optionsByTf).forEach((option) => {
                    if (option.click) {
                        option.click.remove();
                        option.click = null;
                    }
                });
            }
            this.optionsByTf = {};
            this.optionsNode = Dom.place(
                Dom.div({ class: "metadata-def-options h-spaced-8" }),
                this._content,
            );
            this.buildOptions();
            this.updateErrorActions();
            this.highlightSelected();
        }
    }

    /**
     * Display actions related to error values for Types with errors.
     */
    updateErrorActions() {
        if (this.selectedTypeFormat && this.selectedTypeFormat.numErrors > 0) {
            Dom.place(this.errorActions, this.optionsByTf[this.selectedTypeFormat.id].node);
            Dom.show(this.errorActions);
            Dom.show(this._ignoreErrorsButton, !this.ignoringErrors());
        } else {
            Dom.hide(this.errorActions);
        }
    }

    /**
     * Updates all displays of the selected format after the selected format changes.
     */
    updateTypeDisplay() {
        // Check that the type display has been initialized.
        if (this._content) {
            this.updateTab();
            Dom.show(this.formatInfo, this.selectedTypeFormat.type.requiresFormat);
            Dom.show(this.unexpectedTypeWarning, this.unexpectedTypeSelected());
            this.highlightSelected();
            this.updateErrorActions();
        }
    }

    private buildOptions() {
        const areNarrow = this.analysis().numTypeFormats() >= NARROW_COLUMN_NUM;
        const samples = this.analysis().fetchSamples();
        this.hasSamples = samples.length > 0;
        const maxNumFormats = this.analysis().getMaxNumFormats();

        this.analysis().forEachTypeFormatSortedTextFirst((tf) => {
            const radioIcon = new Icon("radio");
            this.toDestroy.push(radioIcon);
            const title = this.buildTitle(tf, maxNumFormats, radioIcon);

            const numValues = this.buildCount("Values", tf.numValues);
            const numErrors = this.buildCount("Errors", tf.getNumErrors(this.ignoreErrors[tf.id]));
            const numNulls = this.buildCount("Null", tf.getNumNulls(this.ignoreErrors[tf.id]));
            const stats = Dom.div(
                { class: "metadata-def-option__stats" },
                numValues,
                numErrors,
                numNulls,
            );

            let samplesNode = Dom.div({ class: "metadata-def-option__samples" });
            if (this.hasSamples) {
                samples
                    .map((origVal) => this.buildSample(tf, origVal))
                    .forEach((cell) => Dom.place(cell, samplesNode));
            } else {
                const noSamples = Dom.td(
                    { class: "metadata-def-option__sample italic" },
                    "All rows had no values",
                );
                Dom.place(noSamples, samplesNode);
            }

            const optionNode = Dom.div(
                { class: "metadata-type-option metadata-def-option action" },
                title,
                stats,
                samplesNode,
            );
            if (areNarrow) {
                Dom.addClass(optionNode, "metadata-def-option--narrow");
            }

            Dom.place(optionNode, this.optionsNode);
            this.optionsByTf[tf.id] = {
                node: optionNode,
                icon: radioIcon,
                typeFormat: tf,
                click: null,
            };
        });
    }

    private buildTitle(tf: TypeFormat, maxNumFormats: number, radioIcon: Icon) {
        return Dom.div(
            { class: "metadata-def-option__title" },
            radioIcon.node,
            Dom.div({ class: "h6" }, tf.displayTitle()),
            Dom.div({ class: "metadata-def-option__subtitle" }, tf.displaySubtitle(maxNumFormats)),
        );
    }

    private buildCount(name: string, count: number) {
        return Dom.div(
            { class: "metadata-def-option__stat" },
            Dom.span({ class: "h7" }, name),
            Dom.span(count),
        );
    }

    private buildSample(tf: TypeFormat, origVal: string) {
        let clazz = "metadata-def-option__sample ellipsed";
        if (tf.type.isRightAligned()) {
            clazz += " align-right";
        }
        const cell = Dom.div({ class: clazz });
        if (tf.isText) {
            cell.textContent = origVal;
            return cell;
        }
        const semantic = tf.sampleValues[origVal];
        if (Is.defined(semantic)) {
            // Ensure that we display a space even if the display value turns out to
            // be blank.
            cell.textContent = semantic.length > 0 ? semantic : E.NBSP;
        } else if (tf.sampleErrors.has(origVal) && !this.ignoringErrors(tf.id)) {
            Dom.addClass(cell, "error-value");
        } else {
            Dom.addClass(cell, "null-value");
        }
        return cell;
    }

    /**
     * Highlights the current selected type (column) in this._table. It is
     * safe to call this method before this.selectedFormat has been set.
     * If it is set it must only be called if this.selectedFormat is
     * currently displayed in this._table; call this.updatePane() first if
     * necessary.
     */
    private highlightSelected() {
        if (!this.hasSamples) {
            // You can't select a type if there are no samples
            return;
        }
        if (this.optionsByTf) {
            Object.values(this.optionsByTf).forEach((option) => {
                Dom.removeClass(option, "metadata-def-option--selected");
                Dom.replaceClass(option.icon, "icon_radio", "icon_radio_filled");
                if (!option.click) {
                    option.click = dojo_on(option.node, Input.tap, () => {
                        ga_event(
                            "Processed Upload",
                            `Select ${option.typeFormat.type.name}`,
                            this.header,
                        );
                        this.def.changeTypeFormat(this, option.typeFormat.id);
                    });
                    this.toDestroy.push(option.click);
                }
            });
        }
        if (!this.selectedTypeFormat) {
            return;
        }
        const selectedOption = this.optionsByTf[this.selectedTypeFormat.id];
        Dom.addClass(selectedOption, "metadata-def-option--selected");
        Dom.replaceClass(selectedOption.icon, "icon_radio_filled", "icon_radio");
        // Make sure that an already-selected cell is not clickable
        selectedOption.click.remove();
        selectedOption.click = null;
    }
}

export = LoadfileColumn;
