import { clsx } from "clsx";
import {
    BasePopover,
    Button,
    ButtonColor,
    ButtonSize,
    PopoverPlacement,
    ProgressBar,
    TextButton,
    useDetectClickOutside,
} from "design-system";
import { EVERID } from "Everlaw/EverAttribute/EverId";
import * as React from "react";
import { ReactElement, ReactNode, RefObject, useCallback, useId, useRef } from "react";
import * as Dom from "Everlaw/Dom";

/**
 * Prop to be supplied to {@link RecommendationOverlay} if the Recommendation has multiple steps.
 */
interface RecommendationProgress {
    /**
     * The index of the current step (zero-indexed).
     */
    currentStep: number;
    /**
     * Total number of steps in the recommendation.
     */
    totalSteps: number;
}

export interface RecommendationOverlayProps {
    id?: string;
    className?: string;
    /**
     * If provided, will render the overlay as a popover with its arrow pointing at the given
     * target. If not provided, the overlay will render as a toast near the top of the page.
     */
    target?: RefObject<Element>;
    /**
     * If provided and the overlay is rendered as a toast, this will set the location on the
     * viewport where the toast will be placed. By default, this location is in the top-middle part
     * of the viewport. Either x, y or both can be specified to override the default coordinates.
     *
     * If {@link target} is defined, this prop is ignored.
     */
    toastLocation?: { x?: number; y?: number };
    show: boolean;
    /**
     * If true, we hide a recommendation overlay's back button by fiat. Useful in situations where a recommendation's
     * first steps have been skipped.
     */
    hideBackButton?: boolean;
    /**
     * Object representing the current progress of the recommendation.
     */
    progress: RecommendationProgress;
    /**
     * Defaults to false.
     */
    isWalkMeTutorial?: boolean;
    /**
     * Defaults to {@link PopoverPlacement.BOTTOM}.
     */
    placement?: PopoverPlacement | PopoverPlacement[];
    onPrev?: () => void;
    onNext?: () => void;
    onDismiss: () => void;
    children: ReactNode;
}

/**
 * Overlay displaying a given step for a Recommendation. This overlay can appear as either a popover
 * or a toast depending on if it is provided with a
 * {@link RecommendationOverlayProps.target target}.
 */
export function RecommendationOverlay({
    id,
    className,
    target,
    toastLocation,
    show,
    hideBackButton = false,
    placement = PopoverPlacement.BOTTOM,
    onPrev = () => {},
    onNext = () => {},
    onDismiss: onDismissProp,
    progress,
    isWalkMeTutorial = false,
    children,
}: RecommendationOverlayProps): ReactElement<RecommendationOverlayProps> {
    // Render the overlay as a popover if a target is provided. Otherwise, render as a toast.
    const isPopover = !!target;
    const isLastStep = progress.currentStep + 1 === progress.totalSteps;
    const isSingleRecommendation = progress.totalSteps === 1;
    const progressDecimal = (progress.currentStep + 1) / progress.totalSteps;

    const onDismiss = useCallback(() => {
        // Return focus to the target element if one is given.
        const targetElement = target?.current;
        if (
            targetElement
            && (targetElement instanceof HTMLElement || targetElement instanceof SVGSVGElement)
        ) {
            targetElement.focus();
        }
        onDismissProp();
    }, [target, onDismissProp]);

    // Use a virtual element as a target when we want to render a toast.
    const toastTarget = useRef({
        getBoundingClientRect() {
            const x = toastLocation?.x ?? window.innerWidth / 2;
            // On pages without a visible header, such as the review window, y could be 0 and the
            // toast would not be visible to the user when rendered. Using 1 as a default allows
            // the toast to be rendered at the top of the page, while still being visible.
            const y = toastLocation?.y ?? Math.max(1, Dom.byId("header").clientHeight);
            return {
                x,
                y,
                top: y,
                left: x,
                bottom: y,
                right: x,
                width: 0,
                height: 0,
            };
        },
    });
    const finalTarget = target || toastTarget;

    const overlayHeadingId = useId();

    const overlayRef = useRef<HTMLDivElement>(null);
    useDetectClickOutside(
        overlayRef,
        useCallback(
            (event: Event) => {
                if (!show) {
                    return;
                }
                if (event instanceof KeyboardEvent) {
                    if (event.key === "Escape") {
                        onDismiss();
                    }
                } else if (event instanceof MouseEvent) {
                    // Let the target element handle clicks on itself.
                    if (event.target instanceof Node && target?.current?.contains(event.target)) {
                        return;
                    }
                    // Single-step recommendations should close when the user clicks outside.
                    if (!progress || isSingleRecommendation) {
                        onDismiss();
                    }
                }
            },
            [show, onDismiss, target, progress, isSingleRecommendation],
        ),
    );

    // Don't render anything onto the DOM if the Recommendation step is not visible. Otherwise,
    // there could be multiple elements on the DOM with the same EverId.
    if (!show) {
        return <></>;
    }

    return (
        <BasePopover
            id={id}
            ref={overlayRef}
            everId={EVERID.RECOMMENDATION.OVERLAY}
            role={"dialog"}
            aria-labelledby={overlayHeadingId}
            className={clsx("recommendation-overlay", className)}
            target={finalTarget}
            arrow={isPopover}
            arrowHeight={isPopover ? undefined : 0}
            show={true}
            placement={placement}
            renderOutsideParent={true}
            modal={true}
        >
            <div className={"flex-vertical gap-8"}>
                {!isSingleRecommendation && (
                    <ProgressBar
                        progress={progressDecimal}
                        aria-label={"Recommendation progress"}
                    />
                )}
                <h2 id={overlayHeadingId} className={"recommendation-overlay__heading"}>
                    RECOMMENDATION
                </h2>
            </div>
            {children}
            <hr className={"recommendation-overlay__footer-divider"} />
            <div className={"flex-horizontal gap-16"}>
                <TextButton everId={EVERID.RECOMMENDATION.OVERLAY_DISMISS} onClick={onDismiss}>
                    Dismiss
                </TextButton>
                <div className={"flex-horizontal gap-8"}>
                    {progress.currentStep !== 0 && !hideBackButton && (
                        <Button
                            onClick={onPrev}
                            color={ButtonColor.SECONDARY}
                            size={ButtonSize.SMALL}
                        >
                            Back
                        </Button>
                    )}
                    {(progress.totalSteps > 1 || isWalkMeTutorial) && (
                        <Button
                            everId={isLastStep ? EVERID.RECOMMENDATION.OVERLAY_DONE : undefined}
                            onClick={onNext}
                            size={ButtonSize.SMALL}
                        >
                            {isLastStep ? (isWalkMeTutorial ? "Start tutorial" : "Done") : "Next"}
                        </Button>
                    )}
                </div>
            </div>
        </BasePopover>
    );
}
