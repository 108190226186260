import * as Arr from "Everlaw/Core/Arr";
import * as Base from "Everlaw/Base";
import * as DateUtil from "Everlaw/DateUtil";
import {
    ModelUsagePredictionModelInfo,
    PerformanceMetrics,
} from "Everlaw/MultiMatterModels/PredictionModelInfo";
import { MultiMatterObject } from "Everlaw/MultiMatterModels/MultiMatterObject";
import * as Project from "Everlaw/Project";
import * as Rest from "Everlaw/Rest";

export enum Action {
    ADD = "ADD",
    REMOVE = "REMOVE",
    UPDATE = "UPDATE",
}

export interface MultiMatterModelUpdateHistoryEntry {
    action: Action;
    timestamp: number;
    multiMatterSelectableModelId: number;
}

export class MultiMatterModel extends MultiMatterObject {
    static readonly MAX_SELECTED_MODELS_NUM = 100;
    override get className(): string {
        return "MultiMatterModel";
    }
    override id: Id;
    name: string;
    // Overriding type here to make it explicit that they cannot be undefined in MultiMatterModel,
    // unlike MultiMatterSelectableModel.
    override type: string;
    selectableModelIds: number[];
    updateHistory: MultiMatterModelUpdateHistoryEntry[];
    modelUsages?: ModelUsagePredictionModelInfo[];
    // When loading MultiMatterModel in a project's Prediction Model page, we don't want to load the modelUsages from
    // parcels since they are sensitive info that should be secured from a single project's users. Instead, we just
    // load the projectIds of the projects the MultiMatterModel is used in.
    // Details in: getProjectMultiMatterModels.rest.
    projectIds?: number[];
    performanceMetricsLoaded = false;

    getNumSelectableModels(): number {
        return this.selectableModelIds.length;
    }

    hasSelectableModel(selectableModelId: number): boolean {
        return new Set(this.selectableModelIds).has(selectableModelId);
    }

    getNumUsage(): number {
        // There is only a single usage of MultiMatterModel per project.
        return this.getProjectIds().size;
    }

    getLastUpdated(): number {
        // Technically, a MultiMatterModel should always have more than 1 history entry since
        // creating a MultiMatterModel always generate its first history entry. This check here is just
        // an insurance.
        return this.updateHistory[0]?.timestamp;
    }

    getLastUpdatedDisplay(): string {
        const lastUpdated = this.getLastUpdated();
        return lastUpdated ? DateUtil.displayDateTimeLocal(lastUpdated) : "N/A";
    }

    getProjectIds(): Set<number> {
        return this.projectIds
            ? Arr.toSet(this.projectIds)
            : Arr.toSet((this.modelUsages ?? []).map((info) => info.projectId));
    }

    isUsedInProject(projectId: Project.Id): boolean {
        return this.getProjectIds().has(projectId);
    }

    updateProjectIds(projectId: number, add = true): void {
        if (this.projectIds) {
            const projectIdsSet = Arr.toSet(this.projectIds);
            add ? projectIdsSet.add(projectId) : projectIdsSet.delete(projectId);
            this.projectIds = Array.from(projectIdsSet);
        }
    }

    override display(): string {
        return this.name;
    }

    getPerformanceMetrics(): Promise<void> {
        if (!this.modelUsages?.length) {
            return Promise.reject();
        }

        if (this.performanceMetricsLoaded) {
            return Promise.reject();
        }

        return Rest.get("getMultiMatterModelPerformanceMetrics.rest", {
            id: this.id,
        }).then(
            (
                performanceMetricsArray: {
                    projectId: number;
                    performanceMetrics: PerformanceMetrics;
                }[],
            ) => {
                const performanceMetricsMap = Arr.toMap(
                    performanceMetricsArray,
                    (t) => t.projectId,
                    (t) => t.performanceMetrics,
                );
                this.modelUsages?.forEach((usage) => {
                    usage.performanceMetrics = performanceMetricsMap.get(usage.projectId);
                });
                this.performanceMetricsLoaded = true;
            },
        );
    }
}

export type Id = number & Base.Id<"MultiMatterModel">;
