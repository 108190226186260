/**
 * The various states a {@link Recommendation} could be in based on user interaction.
 */
export enum RecommendationAcknowledged {
    /**
     * User has clicked the dismiss button, dismissing the {@link Recommendation}.
     */
    DISMISSED = "DISMISSED",
    /**
     * User has advanced the {@link Recommendation} some of the way through, but not all the way
     */
    PARTIALLY_COMPLETE = "PARTIALLY_COMPLETE",
    /**
     * User has advanced all the way through the {@link Recommendation}.
     */
    COMPLETE = "COMPLETE",
}
