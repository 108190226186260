/**
 * Provides replacements for setTimeout that fulfill three key properties:
 *  - All functions return Promises, making them more composable with other Promise-handling code.
 *  - Efforts are made to avoid setTimeout clamping that occurs from the main window in (at least)
 *    Chrome and Firefox.
 *  - The semantics are the same used from the main window or from a Web Worker.
 */
import Is = require("Everlaw/Core/Is");
import Ticker_type = require("Everlaw/EntryPoints/WebWorkers/Ticker");
import WebWorker_type = require("Everlaw/WebWorker");

function useSetTimeout(ms: number) {
    if (ms <= 0) {
        return Promise.resolve();
    }
    return new Promise<void>((resolve) => {
        setTimeout(resolve, ms);
    });
}

/**
 * Returns a Promise that resolves as close to the given timestamp as possible.
 */
export const waitTill = Is.WEB_WORKER
    ? function (timestamp: number) {
          return useSetTimeout(timestamp - Date.now());
      }
    : (function () {
          // Because we are not running from a Web Worker, it is safe to use dojo and non-Core libraries.
          // We want to bounce our message off a version of ourselves loaded in a WebWorker so that we can
          // get better precision, especially when a tab is in the background (in such cases, both Chrome
          // and Firefox (at the least) clamp to 1 second intervals).
          let workerTicker: typeof Ticker_type;
          import("Everlaw/WebWorker").then((WebWorker: typeof WebWorker_type) => {
              if (WebWorker.isSupported) {
                  WebWorker.load<typeof Ticker_type>("Ticker").then((Ticker) => {
                      workerTicker = Ticker;
                  });
              }
          });
          return function (timestamp: number): Promise<void> {
              if (workerTicker) {
                  return (
                      workerTicker
                          .waitTill(timestamp)
                          // We sometimes see Network Error when using Web Workers. Since we can do a timeout
                          // without Web Workers, we'll fall back to the setTimeout approach.
                          .catch(() => useSetTimeout(timestamp - Date.now()))
                  );
              }
              return useSetTimeout(timestamp - Date.now());
          };
      })();

/**
 * Returns a Promise that resolves in the given number of ms, to the best degree of accuracy we can
 * manage.
 */
export const wait = Is.WEB_WORKER
    ? useSetTimeout
    : function (ms: number) {
          if (ms <= 0) {
              return Promise.resolve();
          }
          // From the main window, we are going to want to bounce requests off a Web Worker version of
          // ourselves. Because this takes a non-trivial amount of time, we want to start the clock now so
          // that the request completes at the most accurate time possible.
          return waitTill(Date.now() + ms);
      };

/**
 * Returns a Promise that resolves to the result of `p` if `p` completes before `time` milliseconds,
 * otherwise returning a rejected promise using `msg`.
 *
 * Note: if the timeout is reached the result of `p` is ignored, but p's asynchronous operation is not
 * canceled.
 */
export function timeout<R>(p: Promise<R>, time: number, msg = "Operation timed out") {
    let id: number;
    const delayed = new Promise<R>((resolve, reject) => {
        id = setTimeout(() => {
            reject(msg);
        }, time);
    });
    return Promise.race([p, delayed]).then(
        (r: R) => {
            clearTimeout(id);
            return r;
        },
        (err) => {
            clearTimeout(id);
            throw err;
        },
    );
}
