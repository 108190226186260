import Base = require("Everlaw/Base");

/**
 * Any class that supports auto coding should implement this.
 */
interface Autocodable extends Base.Colored {
    name: string;
    descriptiveText(): HTMLElement;
    designationType(): Autocodable.Type;
}
module Autocodable {
    /**
     * This enum has a corresponding enum in Autocodable.java. Any changes here should be
     * reflected there as well.
     */
    export enum Type {
        CATEGORY = "CATEGORY",
        RATING = "RATING",
        FREEFORM_CODE = "FREEFORM_CODE",
    }
}

export = Autocodable;
