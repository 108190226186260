import * as Base from "Everlaw/Base";

class ClusterInclusion extends Base.Primitive<string> {
    /*
     * This set mirrors enum ClusterInclusion in ClusteredDocument.java.
     */
    static readonly LABELED = new ClusterInclusion("LABELED", "Labeled");
    static readonly OUTLIER = new ClusterInclusion("OUTLIER", "Outlier");
    static readonly ALL = new ClusterInclusion("ALL", "All");

    override get className() {
        return "ClusterInclusion";
    }

    private constructor(
        id: string,
        public override name: string,
    ) {
        super(id, name);
        Base.add(this);
    }

    static allClusterInclusions(): ClusterInclusion[] {
        return [ClusterInclusion.LABELED, ClusterInclusion.OUTLIER, ClusterInclusion.ALL];
    }
}

export = ClusterInclusion;
