import { PopoverPlacement } from "design-system";
import * as Bugsnag from "Everlaw/Bugsnag";
import { EverId } from "Everlaw/EverAttribute/EverId";
import type { Recommendation } from "Everlaw/SmartOnboarding/Recommendation";
import { fromCurrentPage, isSubpageOf } from "Everlaw/SmartOnboarding/RecommendationNavigationPage";
import {
    getActiveRecommendation,
    recommendationsLoaded,
} from "Everlaw/SmartOnboarding/RecommendationSharedVariables";

const TIMEOUT_LENGTH = 1000;

export default class RecommendationStep {
    displayer?: StepDisplayer<unknown>;
    activated = false;
    redirectBack: string;
    currentParams: unknown;
    /**
     * When this {@link RecommendationStep} is {@link #activated}, we may not have registered a
     * {@link StepDisplayer} with it. This could be for a number of reasons. For instance, the
     * code that registers the displayer could be async and execute after we've loaded and
     * activated {@link Recommendation recommendations}.
     *
     * We'll wait {@link TIMEOUT_LENGTH} for the recommendation to be registered. If the timeout
     * is reached before a displayer has been registered, we'll consider the recommendation unable
     * to be shown (this could happen for recommendations that rely on an assignment card for
     * example that the user deleted before we were able to show the recommendation). In such
     * instances, we'll un-trigger the recommendation.
     */
    private displayerTimeoutId?: number;

    constructor(
        public parent: Recommendation,
        public stepNumber: number,
    ) {}

    /**
     * Checks if the current page is supported. If we're skipping this {@link RecommendationStep},
     * the page is implicitly supported. We only check supported pages for the first step because
     * we only care about being on the correct page when the recommendation starts.
     */
    private onSupportedPage(): boolean {
        if (this.displayer?.shouldSkip?.() || this.stepNumber !== 0) {
            return true;
        }
        const currentPage = fromCurrentPage();
        return (
            !!currentPage
            && !!this.parent.supportedPages.find((page) => isSubpageOf(currentPage, page))
        );
    }

    /**
     * Start a timeout for the currently activated {@link RecommendationStep}. See
     * {@link #displayerTimeoutId} for more details.
     */
    private startDisplayerTimeout(): void {
        // If we already have a timeout registered, reset it.
        if (this.displayerTimeoutId) {
            this.clearDisplayerTimeout();
        }
        this.displayerTimeoutId = window.setTimeout(() => {
            this.deactivate();
            this.parent.setAsDeactivated();
            this.parent.resetRecommendationStatus();
            this.displayerTimeoutId = undefined;
            // This isn't necessarily an error. We might expect this if we rely on a certain UI
            // element that doesn't exist anymore (perhaps because the user deleted the underlying
            // object). But, it also could be indicative of an error, so we should report it and
            // confirm.
            Bugsnag.notify(
                new Error(
                    `Timed out waiting for a displayer for ${this.parent.getRecommendationKey()} step ${this.stepNumber}.`,
                ),
            );
        }, TIMEOUT_LENGTH);
        return;
    }

    private clearDisplayerTimeout(): void {
        window.clearTimeout(this.displayerTimeoutId);
        this.displayerTimeoutId = undefined;
    }

    /**
     * Assigns a {@link StepDisplayer} to this {@link RecommendationStep}.
     *
     * Additionally, the step will be activated and shown to the user if {@link #shouldActivate}
     * is true or if {@link #activated} is true.
     */
    async registerDisplayer(
        stepDisplayer: StepDisplayer<unknown>,
        shouldActivate = false,
    ): Promise<void> {
        if (this.displayer) {
            throw new Error(
                `Already registered a displayer for ${this.parent.getRecommendationKey()} step ${this.stepNumber}`,
            );
        }
        this.displayer = stepDisplayer;
        // This is designed to avoid the errors induced by registering a recommendation on an
        // unsupported page.
        if (!this.onSupportedPage()) {
            this.displayer = undefined;
            return;
        }
        this.parent.recommendationChain.registerNode(
            this.stepNumber,
            this,
            this.displayer.placement,
            this.displayer.node,
            this.displayer.isWalkMeTutorial,
            this.displayer.shouldAddActionNode,
            this.displayer.shouldSkip,
            this.displayer.hideBackButton,
            this.displayer.toastLocation,
            this.displayer.displayOverDialogOrPopover,
        );
        if (this.activated || shouldActivate) {
            await this.activate();
        }
    }

    /**
     * Registers the current {@link RecommendationStep} with a new {@link StepDisplayer}. If the
     * displayer was in the middle of creating a {@link RecommendationOverlay}, the overlay will
     * be destroyed once it has finished building.
     *
     * If this step is currently {@link #activated}, this will reactivate the recommendation with
     * a new overlay.
     */
    async reregisterDisplayer(stepDisplayer: StepDisplayer<unknown>): Promise<void> {
        const activated =
            this.activated && this.parent.recommendationChain.currentIdx === this.stepNumber;
        if (activated) {
            /*
             * If the recommendation is already active, deactivate it temporarily while it's moved.
             */
            this.deactivate();
        }
        this.displayer = undefined;
        this.parent.recommendationChain.unregisterNode(this.stepNumber);
        await this.registerDisplayer(stepDisplayer, activated);
    }

    registerRedirectBack(redirectBack: string): void {
        this.redirectBack = redirectBack;
    }

    async activate(forward = true, bypassOnActivate = false): Promise<void> {
        await recommendationsLoaded;
        const canActivate = await this.parent.canActivate();
        if (!this.onSupportedPage() || !canActivate) {
            return;
        }
        // Activating a step should also activate the parent.
        const activeRecommendation = getActiveRecommendation();
        if (activeRecommendation === null) {
            if (!this.parent.setAsActivated()) {
                return;
            }
        } else if (activeRecommendation !== this.parent) {
            // There is another recommendation currently active, so don't try to activate.
            return;
        }
        this.activated = true;
        if (!this.displayer) {
            this.startDisplayerTimeout();
            return; // activated but nothing to show
        }
        this.clearDisplayerTimeout();
        if (forward && this.displayer.shouldSkip?.()) {
            await this.skipStep();
            return;
        }
        if (!bypassOnActivate) {
            this.displayer?.onActivate?.();
        }
        await this.parent.recommendationChain.activate(this.stepNumber);
        await this.parent.setShown();
    }

    deactivate(closeTooltip = true): void {
        this.activated = false;
        if (closeTooltip) {
            this.parent.recommendationChain.closeIfExists(this.stepNumber);
        }
    }

    getNextStep(): RecommendationStep | null {
        if (this.stepNumber + 1 >= this.parent.numberOfSteps()) {
            return null;
        }
        return this.parent.getStep(this.stepNumber + 1);
    }

    /**
     * Save the next {@link RecommendationStep} in the chain as active. This will associate the
     * active {@link RecommendationStep} with the current user HTTP session.
     *
     * @param step the {@link RecommendationStep} we'll save as active.
     * @param uriFrom the {@link URL} we'll save in order to later navigate back to if the user
     * requests it.
     */
    async setStepAsActive(step: number, uriFrom?: string): Promise<void> {
        if (step < 0 || step >= this.parent.numberOfSteps()) {
            throw new Error("Invalid step number passed to setStepAsActive");
        }
        await this.parent.persistInSessionTransient(step, uriFrom);
    }

    async redirectToNextStep(): Promise<void> {
        await this.setStepAsActive(this.stepNumber + 1, window.location.href);
        if (this.displayer?.redirectNext) {
            window.location.href = this.displayer.redirectNext;
        }
    }

    private validateRedirect(): boolean {
        if (!this.displayer?.redirectNext) {
            return false;
        }
        const newUrl = new URL(this.displayer.redirectNext, window.location.href);
        if (window.location.pathname === newUrl.pathname) {
            Bugsnag.notify(
                Error(
                    "Trying to redirect recommendation to the same page. Consider "
                        + "changing the URL hash instead if applicable.",
                ),
            );
        }
        return true;
    }

    async runNextFunction(): Promise<void> {
        if (this.validateRedirect()) {
            await this.redirectToNextStep();
        } else {
            this.currentParams = this.displayer?.nextFunctionInverseParams?.();
            this.displayer?.nextFunction?.();
        }
    }

    async activateNextStep(bypassOnActivate = false): Promise<void> {
        if (this.activated) {
            const nextStep = this.getNextStep();
            this.deactivate();
            if (!this.displayer?.excludeFromStepHistory) {
                await this.parent.appendToHistory(this.stepNumber);
            }
            await this.runNextFunction();
            nextStep?.activate(undefined, bypassOnActivate);
            if (!nextStep) {
                this.onDismiss();
                this.parent.recommendationChain.destroy();
            }
            await this.parent.acknowledge(!!nextStep);
        } else if (this.displayer?.alwaysRunNextFunction) {
            await this.runNextFunction();
        }
    }

    async skipStep(): Promise<void> {
        if (this.displayer?.alwaysRunNextFunction) {
            await this.runNextFunction();
        }
        this.displayer?.onBeforeSkip?.();
        this.deactivate(false);
        this.getNextStep()?.activate();
    }

    async activatePreviousStep(): Promise<void> {
        if (!this.activated) {
            return;
        }
        const previousStep = await this.parent.popFromHistory();
        if (previousStep !== undefined) {
            this.deactivate();
            await this.parent.getStep(previousStep).activateFromNextStep();
        }
    }

    async activateReverse(): Promise<void> {
        this.displayer?.nextFunctionInverse?.(this.currentParams);
        await this.activate(false);
    }

    async activateFromNextStep(): Promise<void> {
        this.parent.recommendationChain.closeIfExists(this.stepNumber + 1);
        if (this.redirectBack) {
            this.setStepAsActive(this.stepNumber).then(
                () => (window.location.href = this.redirectBack),
            );
        } else {
            await this.activateReverse();
        }
    }

    onDismiss(): void {
        this.parent.onDismiss();
    }
}

export interface StepDisplayer<T> {
    /**
     * The {@link EverId} of the target node that the Recommendation overlay should be attached to.
     * This should be specified iff the Recommendation overlay should be displayed as a Popover.
     * Omit this parameter if the overlay should be displayed as a Toast.
     */
    node?: EverId;
    /**
     * The placement(s) of the Recommendation overlay relative to {@link node} if the overlay should
     * be displayed as a Popover.
     */
    placement?: PopoverPlacement[];
    /**
     * Set to true if clicking the {@link node} should advance the Recommendation and invoke
     * {@link nextFunction}. True by default.
     */
    shouldAddActionNode?: boolean;
    /**
     * Callback for when the Recommendation overlay's next button is clicked.
     */
    nextFunction?: () => void;
    /**
     * A function that does the reverse of {@link nextFunction}. It will be called when the back
     * button is clicked from the next step.
     * @param params The output of {@link nextFunctionInverseParams}
     */
    nextFunctionInverse?: (params: T) => void;
    /**
     * This function will be called just before running {@link nextFunction}. Its output will be
     * passed to {@link nextFunctionInverse}. Use it to remember the state before
     * {@link nextFunction} was run.
     */
    nextFunctionInverseParams?: () => T;
    /**
     * This can be used instead of {@link nextFunction}, in case the step redirects the user to a
     * new page. This will be the link to the new page. Similarly,
     * {@link RecommendationStep.redirectBack} can also be set but with
     * {@link RecommendationStep.registerRedirectBack}.
     */
    redirectNext?: string;
    /**
     * Callback for when the step is activated.
     */
    onActivate?: () => void;
    /**
     * Callback that is run when the recommendation is closed. In most cases, it's the inverse of
     * {@link onActivate}.
     */
    onRecommendationClose?: () => void;
    /**
     * Sometimes we may temporarily disable the base node's functionality while the recommendation
     * is active so that it doesn't interfere with the recommendation system. In this case, the
     * recommendation is responsible for running {@link nextFunction} if the base node is clicked.
     * Use this option if that is the case. Keep in mind the {@link nextFunction} will run even if
     * this step is skipped.
     */
    alwaysRunNextFunction?: boolean;
    /**
     * Function that returns true if this step should be skipped.
     */
    shouldSkip?: () => boolean;
    /**
     * Optional callback that is run if we're skipping the current step.
     */
    onBeforeSkip?: () => void;
    /**
     * Whether to hide the back button. Useful in situations where we've skipped initial steps.
     */
    hideBackButton?: boolean;
    /**
     * Whether to disable the node a {@link RecommendationStep} is attached to on close.
     * True by default.
     */
    disableNodeOnClose?: boolean;
    /**
     * When the back button is clicked from the next step, skip this step and go to the one before.
     */
    excludeFromStepHistory?: boolean;
    /**
     * If the recommendation wants to trigger a walkme tutorial, set this to true.
     * False by default.
     */
    isWalkMeTutorial?: boolean;
    /**
     * If provided and the step overlay is to  be rendered as a toast, this will set the location on
     * the viewport where the toast will be placed. By default, this location is in the top-middle
     * part of the viewport. Either x, y, or both can be specified to override the default
     * coordinates.
     */
    toastLocation?: { x?: number; y?: number };
    /**
     * If `true`, the `zIndex` of the {@link RecommendationOverlay} associated with this
     * {@link RecommendationStep} will be increased to above that of a dialog or `dojo` popover.
     *
     * By default, we'll try to detect this based on the element the overlay targets (see
     * {@link RecommendationChain#getOverlayClassName}), but if the overlay does not have a target
     * element, and the recommendation needs to appear above a dialog or popover, this should be
     * set to `true`.
     */
    displayOverDialogOrPopover?: boolean;
}
