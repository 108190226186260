import Button = require("Everlaw/UI/Button");
import Dom = require("Everlaw/Dom");
import Toggle = require("Everlaw/UI/Toggle");

/**
 * A button with two states.
 */
class ToggleButton extends Toggle {
    onLabel: string;
    offLabel: string;
    private btn: Button;
    constructor(params: ToggleButton.Params) {
        super();
        Object.assign(this, params);
        const buttonParams = params.buttonParams || {};
        this.btn = new Button(
            Object.assign(buttonParams, {
                class: "toggle " + (buttonParams.class || ""),
                onClick: this.onClick.bind(this),
            }),
        );
        this.node = this.btn.node;
        this.registerDestroyable(this.btn);
        this.initToggle(params.state, params.disabled);
    }
    setLabel(label: string) {
        this.btn.setContent(label);
    }
    setIconClass(newIconClass: string): void {
        this.btn.setIconClass(newIconClass);
    }
    setHoverIconClass(newHoverIconClass: string): void {
        this.btn.setHoverIconClass(newHoverIconClass);
    }
    getLabel(): string {
        return this.node.textContent;
    }
    protected override _set(st: boolean) {
        super._set(st, false);
        if (this.onLabel || this.offLabel) {
            this.setLabel(st ? this.onLabel : this.offLabel);
        }
    }
}

module ToggleButton {
    export interface Params extends Toggle.Params {
        onLabel?: string;
        offLabel?: string;
        buttonParams?: Button.Params;
    }
}

export = ToggleButton;
