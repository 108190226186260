import Base = require("Everlaw/Base");
import { ColorTokens } from "design-system";
import Str = require("Everlaw/Core/Str");
import { Color } from "Everlaw/ColorUtil";

class PromotionReason extends Base.Object implements Base.Colored {
    static readonly COLOR = Color.fromEverColor(ColorTokens.OBJECT_PROMOTION);
    get className() {
        return "Promotion";
    }
    override id: PromotionReason.Id;
    writable: boolean;
    constructor(params: any) {
        super(params);
        this.mixin(params);
    }

    private mixin(params: any) {
        Object.assign(this, params);
    }

    getColor() {
        return PromotionReason.COLOR;
    }

    override display() {
        const name = Str.capitalize(this.id.replace(new RegExp("_", "g"), " ").toLowerCase());
        return this.writable ? name : "(" + name + ")";
    }
}

module PromotionReason {
    function makePromotion(id: string, writable = true) {
        return Base.set(PromotionReason, new PromotionReason({ id, writable }));
    }

    export const UPLOADED_DIRECTLY = makePromotion("UPLOADED_DIRECTLY", false);
    export const CUSTODIAN = makePromotion("CUSTODIAN");
    export const DATE_RANGE = makePromotion("DATE_RANGE");
    export const KEYWORD = makePromotion("KEYWORD");
    export const OTHER = makePromotion("OTHER");
    export const PRODUCED = makePromotion("PRODUCED", false);
    export const UNITIZED = makePromotion("UNITIZED", false);

    const BY_NAME_MAP = {};

    Base.get(PromotionReason).forEach((reason) => {
        BY_NAME_MAP[reason.display()] = reason;
    });

    export function byName(name: string) {
        return BY_NAME_MAP[name];
    }

    export type Id = string & Base.Id<"Promotion">;
}

export = PromotionReason;
