import Preference = require("Everlaw/Preference");
import { checkPermissions, Permissions } from "Everlaw/HeaderPermissionsUtil";
import * as Project from "Everlaw/Project";
import * as Rest from "Everlaw/Rest";
import { isSbFree } from "Everlaw/Server";
import { RecommendationAcknowledged } from "Everlaw/SmartOnboarding/RecommendationAcknowledged";
import RecommendationChain from "Everlaw/SmartOnboarding/RecommendationChain";
import { Recommendations } from "Everlaw/SmartOnboarding/RecommendationConstants";
import {
    getActiveRecommendation,
    recommendationsLoaded,
    setActiveRecommendation,
} from "Everlaw/SmartOnboarding/RecommendationSharedVariables";
import { RecommendationLevel } from "Everlaw/SmartOnboarding/RecommendationLevel";
import { RecommendationNavigationPage } from "Everlaw/SmartOnboarding/RecommendationNavigationPage";
import { PreferencePage } from "Everlaw/SmartOnboarding/RecommendationPreferencePage";
import {
    RecommendationStatus,
    RecommendationStatusRestData,
} from "Everlaw/SmartOnboarding/RecommendationStatus";
import RecommendationStep from "Everlaw/SmartOnboarding/RecommendationStep";
import { hasVisibleChrons } from "Everlaw/SmartOnboarding/RecommendationUtil";
import { ReactNode } from "react";
import * as User from "Everlaw/User";

/**
 * See {@link RecommendationProps#hasPermission}.
 */
interface PermissionCheckProps {
    /**
     * If not provided, we will call {@link checkPermissions} before supplying these props to
     * {@link RecommendationProps#hasPermission}.
     */
    userPerms: Permissions;
    /**
     * Optional function to check whether a user has access to a chronology.
     */
    hasVisibleChrons?: () => boolean;
}

interface RecommendationProps {
    /**
     * The name of the {@link Recommendation} as seen by the user. This should be a user-friendly
     * display string.
     */
    displayName: string;
    /**
     * Each message represents a step of the Recommendation and corresponds to the text shown to
     * the user at each step.
     */
    messages: ReactNode[];
    /**
     * This should match Recommendation.java. A Recommendation's level does not change. See
     * RecommendationLeve.java for an explanation of each level.
     */
    recLevel: RecommendationLevel;
    /**
     * This should match Recommendation.java. A pagePref corresponds to {@link Preference.RECPAGES}
     * and tells us whether recommendations are globally enabled or disabled on that page.
     */
    pagePref: PreferencePage;
    /**
     * Should match Recommendation.Java. Corresponds to the URLs we want a specific
     * {@link Recommendation} for the initial step of the recommendation. Subsequent steps rely
     * on the logic of previous steps to ensure they're being displayed on a supported page.
     */
    supportedPages: RecommendationNavigationPage[];
    /**
     * The page this {@link Recommendation} starts on in the context of the
     * {@link AllRecommendationsTable}. Typically, this is just {@link PreferencePage.page}, but
     * some recommendations are associated with one page and appear on others. For example, we may
     * have Storybuilder recommendations that only appear in the review window.
     */
    navigationPage?: RecommendationNavigationPage;
    /**
     * Whether this {@link Recommendation} can be seen by the user. Permission checks should
     * primarily be handled by the backend, but we may want to filter recommendations out of
     * the help menu if a user can no longer access a page the recommendation relies on.
     *
     * For instance, if a user triggered a production related recommendation, but then lost
     * permission to view productions, it would not be helpful to let them view the recommendation
     * from the help menu only for it to do nothing.
     */
    hasPermission?: (permissionCheckProps: PermissionCheckProps) => boolean;
    /**
     * Whether we need to check if a user has access to a chronology.
     */
    shouldCheckChronAccess?: boolean;
    /**
     * Whether the {@link Recommendation} can be viewed in the context of a Storybuilder by Everlaw
     * (Storybuilder Free) project. If not defined, defaults to not accessible.
     */
    sbbeAccessible?: boolean;
    /**
     * Whether this {@link Recommendation} is part of our default recommendation subset and
     * appears for all new Everlaw users.
     */
    isDefaultRecommendation?: boolean;
    /**
     * Whether the {@link Recommendation} bypasses user preference checks. This should only be
     * used for recommendations that introduce the user to smart recommendations in general.
     * Project level preferences will still be checked.
     */
    bypassesPreferences?: boolean;
}

/**
 * This class contains constant fields belonging to the recommendation itself (regardless of user,
 * project, org), and dynamic fields that depend on (user, project, org). Constant fields are
 * initialized in {@link Recommendations} and never change. Dynamic fields are initialized in
 * RecommendationInit#init.
 *
 * Most functions that rely on data loaded in from the backend have safe and unsafe versions.
 * Unsafe means they do not check whether recommendations have been loaded into the page before
 * returning the data (see {@link recommendationsLoaded}). Safe functions are async, and await
 * {@link recommendationsLoaded} before returning data. In general, you should prefer to use the
 * safe version.
 */
export class Recommendation {
    readonly messages: ReactNode[];
    readonly displayName: string;
    readonly recommendationChain: RecommendationChain;
    readonly recLevel: RecommendationLevel;
    readonly pagePref: PreferencePage;
    readonly supportedPages: RecommendationNavigationPage[];
    readonly steps: RecommendationStep[];
    readonly navigationPage: RecommendationNavigationPage;
    readonly hasPermission: (hasPermissionProps?: PermissionCheckProps) => boolean;
    /**
     * The {@link RecommendationStatus status} of this {@link Recommendation} for the current
     * user.
     */
    private readonly status: RecommendationStatus = new RecommendationStatus();
    private readonly isDefaultRecommendation: boolean;
    private readonly bypassesPreferences: boolean;

    constructor(props: RecommendationProps) {
        this.messages = props.messages;
        this.displayName = props.displayName;
        this.recLevel = props.recLevel;
        this.pagePref = props.pagePref;
        this.supportedPages = props.supportedPages;
        if (this.supportedPages.length === 0) {
            throw new Error("Recommendations must be supported on at least 1 page");
        }
        this.recommendationChain = new RecommendationChain(this.messages);
        this.steps = this.messages.map((_, i) => new RecommendationStep(this, i));
        this.navigationPage = props.navigationPage ?? props.pagePref.page;
        this.isDefaultRecommendation = !!props.isDefaultRecommendation;
        this.bypassesPreferences = !!props.bypassesPreferences;
        this.hasPermission = (permProps?: PermissionCheckProps) => {
            // We shouldn't show try to show recommendations on suspended projects.
            if (Project.CURRENT?.suspended) {
                return false;
            }
            if (isSbFree() && !props.sbbeAccessible) {
                return false;
            }
            const definedProps = {
                userPerms: permProps?.userPerms ?? checkPermissions(),
                hasVisibleChrons: permProps?.hasVisibleChrons ?? (() => hasVisibleChrons()),
            };
            if (props.shouldCheckChronAccess && !definedProps.hasVisibleChrons()) {
                return false;
            }
            return props.hasPermission === undefined || props.hasPermission?.(definedProps);
        };
    }

    setRecommendationKey(key: keyof typeof Recommendations): void {
        this.status.recommendation = key;
    }

    getRecommendationKey(): keyof typeof Recommendations {
        return this.status.recommendation;
    }

    setStatus(status: RecommendationStatusRestData): void {
        Object.assign(this.status, status, {
            acknowledged: status.acknowledged,
            baseObjectId: status.baseObjectId,
            history: status.history ?? [],
        });
    }

    getStep(i: number): RecommendationStep {
        if (i < 0 || i >= this.steps.length) {
            throw new RangeError("Tried to access a recommendation step that doesn't exist.");
        }
        return this.steps[i];
    }

    numberOfSteps(): number {
        return this.steps.length;
    }

    /**
     * Sets the {@link RecommendationStatus#projectId} to the current project. This is necessary
     * if we're attempting to trigger a {@link Recommendation}, but it is not persisted on the
     * backend.
     */
    private setProjectIdToCurrent(): void {
        if (!this.status.projectId) {
            this.status.projectId = Project.CURRENT.id;
        }
    }

    /**
     * Trigger the {@link Recommendation} and update the status of the recommendation on the
     * backend if any field has changed.
     *
     * @param activate optionally show the {@link Recommendation} to the user immediately.
     */
    async trigger(activate = true): Promise<void> {
        await recommendationsLoaded;
        const canActivate = await this.canActivate();
        if (canActivate) {
            let commit = !this.status.triggered;
            this.status.triggered = true;
            if (this.recLevel === RecommendationLevel.EVERYTIME_LEVEL) {
                commit = commit || this.status.shown;
                this.status.shown = false;
            }
            if (commit && this.recLevel === RecommendationLevel.PROJECT_LEVEL) {
                this.setProjectIdToCurrent();
            }
            await this.status.commitUpdate(commit);
            if (!this.status.shown && activate) {
                await this.activate();
            }
        }
    }

    /**
     * Used for if we want to completely undo a recommendation's status.
     */
    async resetRecommendationStatus(): Promise<void> {
        await recommendationsLoaded;
        this.status.shown = false;
        this.status.triggered = false;
        this.status.acknowledged = undefined;
        await this.status.commitUpdate(true);
    }

    async isShown(): Promise<boolean> {
        await recommendationsLoaded;
        return this.status.shown;
    }

    /**
     * @param commit if true, we'll commit this update to the backend if necessary.
     */
    async setShown(commit: boolean = true): Promise<void> {
        await recommendationsLoaded;
        const shouldCommit = commit && !this.status.shown;
        this.status.shown = true;
        await this.status.commitUpdate(shouldCommit);
    }

    async getAcknowledged(): Promise<RecommendationAcknowledged | undefined> {
        await recommendationsLoaded;
        return this.status.acknowledged;
    }

    /**
     * Whether the user has taken an action to acknowledge this {@link Recommendation}. See
     * {@link RecommendationAcknowledged}.
     */
    async isAcknowledged(): Promise<boolean> {
        const acknowledged = await this.getAcknowledged();
        return (
            acknowledged === RecommendationAcknowledged.COMPLETE
            || acknowledged === RecommendationAcknowledged.DISMISSED
        );
    }

    /**
     * Set the current {@link Recommendation recommendation's} {@link RecommendationAcknowledged}
     * state and commit the update to the backend.
     */
    async acknowledge(nextStep: boolean): Promise<void> {
        const previousAckState = await this.getAcknowledged();
        let commit: boolean;
        if (nextStep) {
            commit = previousAckState !== RecommendationAcknowledged.PARTIALLY_COMPLETE;
            this.status.acknowledged = RecommendationAcknowledged.PARTIALLY_COMPLETE;
        } else {
            commit = previousAckState !== RecommendationAcknowledged.COMPLETE;
            this.status.acknowledged = RecommendationAcknowledged.COMPLETE;
        }
        await this.status.commitUpdate(commit);
    }

    async activate(): Promise<void> {
        await recommendationsLoaded;
        const canActivate = await this.canActivate();
        if (!canActivate) {
            return;
        }
        this.status.history = [];
        // This will set the status of the parent to `activated`.
        await this.steps[0].activate();
    }

    async dismiss(): Promise<void> {
        await recommendationsLoaded;
        const commit = this.status.acknowledged !== RecommendationAcknowledged.DISMISSED;
        this.status.acknowledged = RecommendationAcknowledged.DISMISSED;
        await this.status.commitUpdate(commit);
    }

    async reactivate(stepNumber: number, history: number[] = []): Promise<void> {
        await recommendationsLoaded;
        if (stepNumber === 0 || this.steps[stepNumber - 1].activated) {
            if (stepNumber > 0) {
                this.steps[stepNumber - 1].deactivate();
            }
            this.status.history.push(...history);
            await this.steps[stepNumber].activate();
        }
    }

    onDismiss(): void {
        for (const step of this.steps) {
            step.deactivate(false);
            step.displayer?.onRecommendationClose?.();
        }
        this.setAsDeactivated();
    }

    /**
     * Whether an action was taken by the user (or the appropriate conditions have been met) to
     * trigger this {@link Recommendation}. When a recommendation is triggered, if it hasn't yet
     * been shown, it will be shown the next time the user navigates to the page the recommendation
     * should appear on (provided another recommendation does not have priority).
     */
    async triggered(): Promise<boolean> {
        await recommendationsLoaded;
        return this.status.triggered;
    }

    /**
     * Checks whether this {@link Recommendation} has been triggered  by the user. This does not
     * check whether recommendations have been loaded into the page, so this result may
     * be incorrect. Prefer using {@link #triggered} as this method should only be used for
     * rendering components that update frequently.
     */
    isTriggeredUnsafe(): boolean {
        return this.status.triggered;
    }

    /**
     * Checks whether this {@link Recommendation} has been shown to the user. Prefer using
     * {@link #isShown} except in situations where we know recommendations have been loaded.
     */
    isShownUnsafe(): boolean {
        return this.status.shown;
    }

    /**
     * Whether we should activate this {@link Recommendation}.
     */
    async shouldActivate(): Promise<boolean> {
        const isTriggered = await this.triggered();
        const isShown = await this.isShown();
        const isAcked = await this.isAcknowledged();
        return isTriggered && !isShown && !isAcked;
    }

    /**
     * Checks if this {@link Recommendation} has not been viewed by the user. This does not check
     * whether recommendations have been loaded into the page, so this result may be incorrect.
     * This method should only be used in instances where we know recommendations have been loaded
     * into the page, or called frequently enough such that an initial incorrect result does not
     * matter.
     */
    isNotViewedUnsafe(): boolean {
        return this.isTriggeredUnsafe() && this.status.acknowledged === undefined;
    }

    pageDisplayName(): string {
        return this.pagePref.displayName ?? this.pagePref.name;
    }

    /**
     * Whether we're allowed to activate this {@link Recommendation}. If we're on a page that the
     * user does not want to see recommendations on, we should not activate any recommendations
     * associated with the page, unless this recommendation is part of the default subset we
     * want new Everlaw users to see ({@link Preference#RECPAGES#DefaultSubset}). See
     * {@link RecommendationProps#pagePref} for more details.
     *
     * If the current tab or window is not currently visible to the user, we shouldn't activate
     * the recommendation. They cannot see it, and it can lead to a state where we think we've
     * shown the user the recommendation, when in reality they haven't seen it.
     *
     * If the user does not have the minimum permissions to view the recommendation, we shouldn't
     * try to activate it.
     */
    async canActivate(): Promise<boolean> {
        await recommendationsLoaded;
        if (document.hidden || !this.hasPermission()) {
            return false;
        }
        // Only show recommendations to an impersonator if the recommendation was activated from
        // the help menu.
        if (User.me.impersonatorUsername && !this.status.fromHelpMenu) {
            return false;
        }
        // Recommendations activated from the help menu skip the preference check.
        if (this.status.fromHelpMenu) {
            return true;
        }
        const preferences = Preference.RECPAGES;
        // We know a user wants to see recommendations if the global toggle is enabled, and either
        // recommendations are enabled on the page they're currently on, or they've enabled the
        // default subset of recommendations and this recommendation is part of that set.
        const userWantsToSeeRecommendation =
            preferences.AllPages.get()
            && (preferences[this.pagePref.name].get()
                || (this.isDefaultRecommendation && preferences["DefaultSubset"].get()));
        // If this recommendation bypasses user preferences, we should first consider whether the
        // user explicitly wants to see recommendations. If they do, we should still show it to
        // them, even if recommendations are disabled at the project level.
        if (this.bypassesPreferences) {
            return userWantsToSeeRecommendation || preferences.AllPages.getProjectDefault().val;
        }
        return userWantsToSeeRecommendation;
    }

    /**
     * Add the provided step to {@link RecommendationStatus#history}.
     */
    async appendToHistory(step: number): Promise<void> {
        await recommendationsLoaded;
        this.status.history.push(step);
    }

    /**
     * Remove the last {@link RecommendationStatus#step} from {@link RecommendationStatus#history}
     * and return it.
     */
    async popFromHistory(): Promise<number | undefined> {
        await recommendationsLoaded;
        if (!this.status.history) {
            return;
        }
        return this.status.history.pop();
    }

    /**
     * Gets the last history step from {@link RecommendationStatus}. Returns -1 if there's no history.
     */
    lastHistoryStepUnsafe(): number {
        if (this.status.history.length === 0) {
            return -1;
        }
        return this.status.history[this.status.history.length - 1];
    }

    /**
     * Check the history of this recommendation. Useful for if we want to hide the back button.
     */
    async hasEmptyHistory(): Promise<boolean> {
        await recommendationsLoaded;
        return this.status.history.length === 0;
    }

    /**
     * Sets the current {@link Recommendation} as active, and resolves to true if we were
     * successful.
     */
    setAsActivated(): boolean {
        const activeRecommendation = getActiveRecommendation();
        if (!activeRecommendation) {
            setActiveRecommendation(this);
            return true;
        }
        return false;
    }

    /**
     * Sets the current {@link Recommendation} as inactive.
     */
    setAsDeactivated(): void {
        const activeRecommendation = getActiveRecommendation();
        if (activeRecommendation !== this) {
            throw new Error("Unexpected recommendation currently active.");
        }
        setActiveRecommendation(null);
    }

    /**
     * This sets the current {@link RecommendationStatus} as the active recommendation on the
     * backend, allowing the user to navigate to another page and continue progressing through
     * this {@link Recommendation}.
     *
     * The endpoint used to set the active recommendation is different for when we're in the context
     * of a project vs when we aren't to ensure we're accessing the correct session transient cache
     * on the backend.
     */
    async persistInSessionTransient(step: number, uriFrom?: string): Promise<void> {
        await recommendationsLoaded;
        const url = `${Project.CURRENT ? "" : "/"}recommendation/setActiveRecommendation.rest`;
        return Rest.post(url, {
            ...this.status.toCommitRestData(),
            step,
            uriFrom,
            history: this.status.history,
            fromHelpMenu: this.status.fromHelpMenu,
        });
    }

    /**
     * The timestamp at which this {@link Recommendation} was last interacted with by the user.
     * Given we aren't checking for the {@link recommendationsLoaded} promise being resolved, this
     * function is unsafe in that the last modified time may be incorrect. You should await
     * {@link recommendationsLoaded} before calling this to ensure the result is accurate.
     */
    getLastModifiedUnsafe(): number {
        return this.status.lastModified;
    }
}
