import Arr = require("Everlaw/Core/Arr");
import Cmp = require("Everlaw/Core/Cmp");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Tabs = require("Everlaw/UI/Tabs");
import UploadUI = require("Everlaw/Upload/Util/UploadUI");
import Util = require("Everlaw/Util");

export abstract class Tabbable {
    id: string;
    inspected = false;
    protected tabDrawer: HTMLElement = Dom.div({ class: "metadata-tab__drawer hidden" });
    protected _tab: HTMLElement = Dom.div({ class: "metadata-tab__main h-spaced-8" });
    protected _pane: HTMLElement = Dom.div({ class: "metadata-pane hidden" });
    protected toDestroy: Util.Destroyable[] = [];
    private tabContainer: HTMLElement = Dom.div(
        { class: "metadata-tab action" },
        this._tab,
        this.tabDrawer,
    );
    private statusIcon: Icon;

    init() {
        Dom.place((this.statusIcon = new Icon("x-circle-filled-red-20")), this._tab, "first");
        this.toDestroy.push(this.statusIcon);
        this.initTab();
        this.initPane();
    }
    protected abstract initTab(): void;
    protected abstract initPane(): void;

    getTab() {
        return this.tabContainer;
    }
    getPane() {
        return this._pane;
    }

    updateTab() {
        Dom.toggleClass(this.getTab(), "metadata-tab--inspected", this.inspected);

        const className = this.getTabClass();
        Dom.replaceClass(
            this.getTab(),
            className,
            "verification inspection resolution metadata-tab--ignored",
        );

        Dom.show(this.statusIcon);
        let iconName = "";
        if (className === "metadata-tab--ignored") {
            Dom.hide(this.statusIcon);
        } else if (className === "resolution") {
            iconName = UploadUI.INVALID_ICON;
        } else if (className === "inspection") {
            iconName = UploadUI.WARNING_ICON;
        } else if (className === "verification") {
            iconName = UploadUI.SUCCESS_ICON;
        }
        Dom.replaceClass(this.statusIcon, iconName, UploadUI.STATUS_ICON_CLASSES);
    }
    protected abstract getTabClass(): string;

    onShow() {
        // TODO: we could optimize this further by only initializing the pane when it is first shown.
        // That would add updateTab/updatePane as methods of this interface.
        if (!this.inspected) {
            this.inspected = true;
            this.updateTab();
        }
        Dom.show(this.tabDrawer);
        Dom.addClass(this.getTab(), "metadata-tab--selected");
    }

    onHide() {
        Dom.hide(this.tabDrawer);
        Dom.removeClass(this.getTab(), "metadata-tab--selected");
    }

    destroy() {
        Util.destroy(this.toDestroy);
    }
}

export class View {
    node: HTMLElement = Dom.div({ class: "metadata-tab-view h-spaced-8" });
    tabbables: Tabbable[] = [];
    compare: Cmp<Tabbable>;
    onShowTab: () => void;
    private emptyNode: HTMLElement;
    private _tabs: HTMLElement;
    private _panes: HTMLElement;
    private _tabbar: Tabs.TabbedContent;
    private _destroyables: Util.Destroyable[] = [];
    constructor(
        tabbableNoun: string,
        tabbables: Tabbable[],
        compare?: Cmp<Tabbable>,
        onShowTab?: () => void,
    ) {
        this.compare = compare;
        this.onShowTab = onShowTab;
        this._tabs = Dom.create("div", { class: "metadata-tab-container" }, this.node);
        this._panes = Dom.create("div", { class: "metadata-pane-container" }, this.node);
        tabbables.forEach(this.addTabbable, this);
        this.refreshTabs();
        const entries = this.tabbables.map((t) => {
            return {
                id: t.id,
                tab: t.getTab(),
                content: t.getPane(),
            };
        });
        this.emptyNode = Dom.div(
            { class: "metadata-pane-empty h5" },
            `Select a ${tabbableNoun} from the left`,
        );
        Dom.place(this.emptyNode, this._panes);
        this._tabbar = new Tabs.TabbedContent(entries, {
            initiallyEmpty: true,
            onShow: (newlyShown, justHidden) => {
                Dom.hide(this.emptyNode);
                this.tabbables.forEach((tabbable) => {
                    if (tabbable.id === newlyShown) {
                        tabbable.onShow();
                    } else if (tabbable.id === justHidden) {
                        tabbable.onHide();
                    }
                });
                this.onShowTab && this.onShowTab();
            },
        });
    }
    sortTabs() {
        Arr.sort(this.tabbables, { cmp: this.compare });
    }
    refreshTabs() {
        Dom.empty(this._tabs);
        this.sortTabs();
        this.tabbables.forEach((t) => {
            Dom.place(t.getTab(), this._tabs);
        });
    }
    getCurrentId() {
        return this._tabbar.getCurrentId();
    }
    selectTab(id: string) {
        this._tabbar.select(id);
    }
    addTabbable(tabbable: Tabbable) {
        tabbable.init();
        this.tabbables.push(tabbable);
        Dom.place(tabbable.getPane(), this._panes);
        if (this._tabbar) {
            // If the tabbar already exists then we need to register the tab and refresh the display.
            this._tabbar.addTab({
                id: tabbable.id,
                tab: tabbable.getTab(),
                content: tabbable.getPane(),
            });
        }
    }
    removeTabbable(tabbable: Tabbable) {
        this.tabbables.splice(this.tabbables.indexOf(tabbable), 1);
        this._tabbar.removeTab(tabbable.id);
        Dom.remove(tabbable.getPane());
    }
    destroy() {
        Util.destroy(this._destroyables);
        this._tabbar.destroy();
        Dom.destroy(this.node);
    }
}
