type UrlHashParams = { [k: string]: string };

/**
 * The .do endpoints we support {@link Recommendation recommendations} on.
 */
export enum JspPage {
    ANALYTICS = "analytics.do",
    ARGUMENT = "argument.do",
    ASSIGNMENTS = "assignments.do",
    CHRON = "chron.do",
    CLUSTERING = "clustering.do",
    DATA = "data.do",
    DATABASE_SETTINGS = "database.do",
    DEPOSITION = "deposition.do",
    HOME = "home.do",
    LEGAL_HOLDS = "legalHold.do",
    MESSAGES = "messages.do",
    REVIEW = "review.do",
    SEARCH = "search.do",
    SEARCH_TERM_REPORT = "searchTermReport.do",
    SETTINGS = "settings.do",
    STYLE = "style.do",
}

const JSP_PAGE_KEY_LOOKUP = new Map<JspPage, keyof typeof JspPage>(
    Object.entries(JspPage).map(([key, val]) => [val, key as keyof typeof JspPage]),
);

/**
 * Represents all the information we'll need to navigate the user to a given page.
 */
interface NavigationPage {
    /**
     * The parent `.do` endpoint for the page.
     */
    jspPage: JspPage;
    /**
     * The {@link RecommendationNavigationPage} this interface represents. This can be `undefined`
     * in the instance where there is no suitable navigation page, but we still want to represent
     * the page as part of the {@link NavigationPages} tree.
     *
     * For example, {@link JspPage.SEARCH} is not a valid navigation page on its own. We should
     * either navigate to the results table or search builder page. But, we still want it
     * represented in the tree because it's the root of the `search.do` pages.
     */
    navPage?: RecommendationNavigationPage;
    /**
     * Child pages of the main `.do` enpoint. For example `search.do#id=4` for the results table.
     */
    subPages?: Partial<Record<RecommendationNavigationPage, NavigationPage>>;
    /**
     * The url hash parameters we'll need to navigate the user to the page.
     */
    urlHashParams?: UrlHashParams;

    /**
     * Given an id, returns an id hash parameter. This will be combined with {@link #urlHashParams}
     * to create the full url.
     */
    hashWithId?: (id: string) => UrlHashParams;
    /**
     * Given a map of hash parameter keys to values (ex. `{ "tab": "predictive-coding" }` for hash
     * parameters `tab=predictive-coding`), determines whether we're on the current
     * {@link #navPage}. This assumes the root {@link #jspPage} has already been checked. This
     * should also take into account the other pages in the tree.
     */
    isOnPage: (currentHashParams: Map<string, string>) => boolean;
}

/**
 * This maps to RecommendationNavigationPage.java and makes it easy for us to tell the backend
 * what page we want to navigate to. If adding to this enum, make sure to also add a test for
 * it in `RecommendationConstants.test.ts`!
 */
export enum RecommendationNavigationPage {
    ANALYTICS = "ANALYTICS",
    ARGUMENT = "ARGUMENT",
    ASSIGNMENTS = "ASSIGNMENTS",
    CHRON = "CHRON",
    CLUSTERING = "CLUSTERING",
    DATA = "DATA",
    DATABASE_SETTINGS = "DATABASE_SETTINGS",
    DATA_VISUALIZER = "DATA_VISUALIZER",
    DEPOSITION = "DEPOSITION",
    HOME = "HOME",
    LEGAL_HOLDS = "LEGAL_HOLDS",
    MESSAGES = "MESSAGES",
    NATIVE_UPLOADS = "NATIVE_UPLOADS",
    PREDICTIVE_CODING = "PREDICTIVE_CODING",
    PREDICTIVE_CODING_WIZARD = "PREDICTIVE_CODING_WIZARD",
    PROCESSED_UPLOADS = "PROCESSED_UPLOADS",
    PRODUCTIONS = "PRODUCTIONS",
    RESULTS_TABLE = "RESULTS_TABLE",
    REVIEW = "REVIEW",
    SB_DASHBOARD = "SB_DASHBOARD",
    SEARCH_BUILDER = "SEARCH_BUILDER",
    SEARCH_TERM_REPORT = "SEARCH_TERM_REPORT",
    SEARCH_TERM_REPORT_CREATE = "SEARCH_TERM_REPORT_CREATE",
    SETTINGS = "SETTINGS",
    STYLE = "STYLE",
}

/**
 * This represents a {@link JspPage jsp page} we can navigate to. It is more specific than a
 * jsp page in that we can specify hash parameters and ids to reach a page that can't be
 * represented through a jsp page alone.
 *
 * The structure of this object is a tree of pages that branch out into subpages. If a
 * {@link Recommendation} is associated with a particular page in this object, it is also
 * associated with its subpages. So, if a recommendation were supported on the analytics page,
 * but we determine that we're on the predictive coding page, we'd still show that recommendation
 * as it is a subpage of the parent.
 *
 * If updating the structure here, make sure to update it on the backend!
 */
const NavigationPages = {
    ANALYTICS: {
        jspPage: JspPage.ANALYTICS,
        navPage: RecommendationNavigationPage.ANALYTICS,
        subPages: {
            PREDICTIVE_CODING: {
                jspPage: JspPage.ANALYTICS,
                navPage: RecommendationNavigationPage.PREDICTIVE_CODING,
                hashWithId(id: string): UrlHashParams {
                    return { tab: "model" + id };
                },
                isOnPage(currentHashParams: Map<string, string>): boolean {
                    return !!currentHashParams.get("tab")?.includes("model");
                },
            },
            PREDICTIVE_CODING_WIZARD: {
                jspPage: JspPage.ANALYTICS,
                navPage: RecommendationNavigationPage.PREDICTIVE_CODING_WIZARD,
                urlHashParams: {
                    tab: "prediction-wizard-tab",
                },
                isOnPage(currentHashParams: Map<string, string>): boolean {
                    return (
                        !!this.urlHashParams
                        && currentHashParams.get("tab") === this.urlHashParams.tab
                    );
                },
            },
        },
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    ARGUMENT: {
        jspPage: JspPage.ARGUMENT,
        navPage: RecommendationNavigationPage.ARGUMENT,
        hashWithId(id: string): UrlHashParams {
            return { argId: id };
        },
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return currentHashParams.has("argId");
        },
    },
    ASSIGNMENTS: {
        jspPage: JspPage.ASSIGNMENTS,
        navPage: RecommendationNavigationPage.ASSIGNMENTS,
        hashWithId(id: string): UrlHashParams {
            return { id };
        },
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    CHRON: {
        jspPage: JspPage.CHRON,
        navPage: RecommendationNavigationPage.CHRON,
        subPages: {
            SB_DASHBOARD: {
                jspPage: JspPage.CHRON,
                navPage: RecommendationNavigationPage.SB_DASHBOARD,
                urlHashParams: {
                    tabId: "sb-dash",
                },
                isOnPage(currentHashParams: Map<string, string>): boolean {
                    const isOnDashboard =
                        !!this.urlHashParams
                        && currentHashParams.get("tabId") === this.urlHashParams.tabId;
                    // If there are no hash parameters, the Chronology page will initially
                    // select the dashboard.
                    return isOnDashboard || currentHashParams.size === 0;
                },
            },
        },
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    CLUSTERING: {
        jspPage: JspPage.CLUSTERING,
        navPage: RecommendationNavigationPage.CLUSTERING,
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    DATABASE_SETTINGS: {
        jspPage: JspPage.DATABASE_SETTINGS,
        navPage: RecommendationNavigationPage.DATABASE_SETTINGS,
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    SEARCH: {
        jspPage: JspPage.SEARCH,
        subPages: {
            RESULTS_TABLE: {
                jspPage: JspPage.SEARCH,
                navPage: RecommendationNavigationPage.RESULTS_TABLE,
                subPages: {
                    DATA_VISUALIZER: {
                        jspPage: JspPage.SEARCH,
                        navPage: RecommendationNavigationPage.DATA_VISUALIZER,
                        urlHashParams: {
                            view: "datavis",
                        },
                        hashWithId(id: string): UrlHashParams {
                            return { id };
                        },
                        isOnPage(currentHashParams: Map<string, string>): boolean {
                            return (
                                currentHashParams.get("view") === this.urlHashParams?.view
                                && currentHashParams.has("id")
                            );
                        },
                    },
                },
                hashWithId(id: string): UrlHashParams {
                    return { id };
                },
                isOnPage(currentHashParams: Map<string, string>): boolean {
                    return (
                        currentHashParams.has("id")
                        && !NavigationPages["SEARCH"].subPages.SEARCH_BUILDER.isOnPage(
                            currentHashParams,
                        )
                    );
                },
            },
            SEARCH_BUILDER: {
                jspPage: JspPage.SEARCH,
                navPage: RecommendationNavigationPage.SEARCH_BUILDER,
                urlHashParams: {
                    view: "search",
                },
                isOnPage(currentHashParams: Map<string, string>): boolean {
                    return (
                        // If there are no hash params, the search page will redirect to the search
                        // builder.
                        currentHashParams.size === 0
                        || currentHashParams.get("view") === this.urlHashParams?.view
                    );
                },
            },
        },
        isOnPage(currentHashParams: Map<string, string>): boolean {
            // The default search page isn't a valid option. We should either be on the search
            // builder or the results table.
            return false;
        },
    },
    DEPOSITION: {
        jspPage: JspPage.DEPOSITION,
        navPage: RecommendationNavigationPage.DEPOSITION,
        hashWithId(id: string): UrlHashParams {
            return { depoId: id };
        },
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    HOME: {
        jspPage: JspPage.HOME,
        navPage: RecommendationNavigationPage.HOME,
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    MESSAGES: {
        jspPage: JspPage.MESSAGES,
        navPage: RecommendationNavigationPage.MESSAGES,
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    DATA: {
        jspPage: JspPage.DATA,
        navPage: RecommendationNavigationPage.DATA,
        subPages: {
            NATIVE_UPLOADS: {
                jspPage: JspPage.DATA,
                navPage: RecommendationNavigationPage.NATIVE_UPLOADS,
                urlHashParams: {
                    tab: "native-data",
                },
                isOnPage(currentHashParams: Map<string, string>): boolean {
                    return currentHashParams.get("tab") === this.urlHashParams?.tab;
                },
            },
            PROCESSED_UPLOADS: {
                jspPage: JspPage.DATA,
                navPage: RecommendationNavigationPage.PROCESSED_UPLOADS,
                urlHashParams: {
                    tab: "processed-uploads",
                },
                isOnPage(currentHashParams: Map<string, string>): boolean {
                    return currentHashParams.get("tab") === this.urlHashParams?.tab;
                },
            },
            PRODUCTIONS: {
                jspPage: JspPage.DATA,
                navPage: RecommendationNavigationPage.PRODUCTIONS,
                urlHashParams: {
                    tab: "productions",
                },
                isOnPage(currentHashParams: Map<string, string>): boolean {
                    return currentHashParams.get("tab") === this.urlHashParams?.tab;
                },
            },
        },
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    REVIEW: {
        jspPage: JspPage.REVIEW,
        navPage: RecommendationNavigationPage.REVIEW,
        hashWithId(id: string): UrlHashParams {
            return { doc: id };
        },
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return currentHashParams.has("doc");
        },
    },
    SEARCH_TERM_REPORT: {
        jspPage: JspPage.SEARCH_TERM_REPORT,
        navPage: RecommendationNavigationPage.SEARCH_TERM_REPORT,
        subPages: {
            SEARCH_TERM_REPORT_CREATE: {
                jspPage: JspPage.SEARCH_TERM_REPORT,
                navPage: RecommendationNavigationPage.SEARCH_TERM_REPORT_CREATE,
                urlHashParams: {
                    newReport: "true",
                },
                isOnPage(currentHashParams: Map<string, string>): boolean {
                    return (
                        currentHashParams.size === 0
                        || currentHashParams.get("newReport") === this.urlHashParams?.newReport
                    );
                },
            },
        },
        hashWithId(id: string): UrlHashParams {
            return { id };
        },
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return currentHashParams.has("id");
        },
    },
    SETTINGS: {
        jspPage: JspPage.SETTINGS,
        navPage: RecommendationNavigationPage.SETTINGS,
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    STYLE: {
        jspPage: JspPage.STYLE,
        navPage: RecommendationNavigationPage.STYLE,
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
    LEGAL_HOLDS: {
        jspPage: JspPage.LEGAL_HOLDS,
        navPage: RecommendationNavigationPage.LEGAL_HOLDS,
        isOnPage(currentHashParams: Map<string, string>): boolean {
            return true;
        },
    },
} satisfies Record<keyof typeof JspPage, NavigationPage>;

/**
 * Build a traversal array of the {@link NavigationPages} tree from leaves to the root. This
 * traversal will include the entire tree unless `start` is provided.
 */
function buildReverseLevelOrderTraversal(start?: NavigationPage): NavigationPage[] {
    let traversal: NavigationPage[] = start ? [start] : [...Object.values(NavigationPages)];
    let toVisit: NavigationPage[] = [...traversal];
    while (toVisit.length > 0) {
        const page = toVisit.pop();
        if (!page) {
            break;
        }
        if (page.subPages) {
            toVisit = toVisit.concat(Object.values(page.subPages));
            traversal = Object.values(page.subPages).concat(traversal);
        }
    }
    return traversal;
}

/**
 * Predicate for checking if a {@link NavigationPage} has a defined {@link navPage} property.
 */
function hasNavPage(
    page: NavigationPage,
): page is NavigationPage & Required<Pick<NavigationPage, "navPage">> {
    return !!page.navPage;
}

const NAV_PAGE_LOOKUP: Map<RecommendationNavigationPage, NavigationPage> = new Map<
    RecommendationNavigationPage,
    NavigationPage
>(
    buildReverseLevelOrderTraversal()
        .filter(hasNavPage)
        .map((page) => [page.navPage, page]),
);

/**
 * Reverses {@link #stringToHashParamLookup}.
 */
function hashParamsToString(hashParams?: UrlHashParams): string {
    if (!hashParams) {
        return "";
    }
    return Object.keys(hashParams)
        .map((key) => `${key}=${hashParams[key]}`)
        .join("&");
}

/**
 * Given a {@link URL} hash string of the form `a=b&c=d&e=f`, construct a lookup map of the form
 * `{a: b, c: d, e: f}`.
 */
function stringToHashParamLookup(str: string): Map<string, string> {
    const hashParamLookup = new Map<string, string>();
    if (str === "") {
        return hashParamLookup;
    }
    str.split("&").forEach((param) => {
        const splitParams = param.split("=");
        hashParamLookup.set(splitParams[0], splitParams[1]);
    });
    return hashParamLookup;
}

/**
 * Construct a {@link UrlHashParams} object from the given {@link NavigationPage}. If an `id` is
 * provided but the page does not take an `id`, an error will be thrown. Additionally, if the page
 * expects an `id`, but one wasn't provided, an error will be thrown.
 */
function getHashParams(navigationPage: NavigationPage, id?: string): UrlHashParams | undefined {
    if (!id && navigationPage.hashWithId) {
        throw new Error(`Expected ${navigationPage.navPage} to take an id.`);
    }
    if (id && !navigationPage.hashWithId) {
        throw new Error(`Expected ${navigationPage.navPage} to not take an id`);
    }
    if (id && navigationPage.hashWithId) {
        return { ...navigationPage?.urlHashParams, ...navigationPage.hashWithId(id) };
    }
    return navigationPage.urlHashParams;
}

/**
 * Given a {@link RecommendationNavigationPage}, construct a {@link URL} that will navigate the
 * user to that page.
 */
export function getNavigationPageUrl(page: RecommendationNavigationPage, id?: string): URL {
    const navigationPage = NAV_PAGE_LOOKUP.get(page);
    if (!navigationPage) {
        throw new Error("Expected navigation page to exist.");
    }
    const hashParams = getHashParams(navigationPage, id);
    const hash = hashParamsToString(hashParams);
    return new URL(`${navigationPage.jspPage}#${hash}`, window.location.href);
}

/**
 * Parse the current url into a {@link RecommendationNavigationPage}. The structure of
 * {@link NavigationPages} should determine how we search for the correct page. See
 * {@link NavigationPage} for more details.
 */
export function fromCurrentPage(): RecommendationNavigationPage | null {
    const jspEndpoint = window.location.pathname.split("/").pop();
    if (!jspEndpoint) {
        return null;
    }
    const jspPage = JSP_PAGE_KEY_LOOKUP.get(jspEndpoint as JspPage);
    if (!jspPage) {
        return null;
    }
    const hash = window.location.hash.includes("#")
        ? window.location.hash.split("#")[1]
        : window.location.hash;
    const currentHashParams = stringToHashParamLookup(hash);
    // Traverse the parent subtree until the correct navigation page is found.
    return (
        buildReverseLevelOrderTraversal(NavigationPages[jspPage]).find((page) =>
            page.isOnPage(currentHashParams),
        )?.navPage ?? null
    );
}

/**
 * {@link Recommendation Recommendations} associated with a {@link RecommendationNavigationPage}
 * may also be shown on pages that are subpages of the given `parent` page. For example,
 * if we're on the `production` page, and a recommendation is associated with the `data` page (of
 * which `production` is a subpage of), we should still try and show the recommendation. However,
 * if we're on the `data` page and the recommendation is associated with the `production` page,
 * we shouldn't show it as it might rely on UI elements not available to that page.
 */
export function isSubpageOf(
    subPage: RecommendationNavigationPage,
    parent: RecommendationNavigationPage,
): boolean {
    if (subPage === parent) {
        return true;
    }
    const parentPage = NAV_PAGE_LOOKUP.get(parent);
    if (!parentPage) {
        return false;
    }
    return !!buildReverseLevelOrderTraversal(parentPage).find((page) => page.navPage === subPage);
}

/**
 * {@link NavigationPage Navigation pages} in the same subtree should share {@link JspPage}.
 */
export function isSubpageOrAncestorOf(
    page: RecommendationNavigationPage,
    parent: RecommendationNavigationPage,
): boolean {
    if (page === parent) {
        return true;
    }
    const parentPage = NAV_PAGE_LOOKUP.get(parent);
    if (!parentPage) {
        return false;
    }
    const subPage = NAV_PAGE_LOOKUP.get(page);
    if (!subPage) {
        return false;
    }
    return parentPage.jspPage === subPage.jspPage;
}
