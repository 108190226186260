import Base = require("Everlaw/Base");
import Project = require("Everlaw/Project");
import User = require("Everlaw/User");

interface RedactionStampParams {
    id?: number;
    content: string;
    abbreviation: string;
    projectId?: Project.Id;
    userId?: User.Id;
}

class RedactionStamp extends Base.Object {
    get className(): string {
        return "RedactionStamp";
    }

    private userId: User.Id;
    private content: string;
    private abbreviation: string;
    size: number;
    override id: number;
    isFlipped = false;

    constructor(params: RedactionStampParams) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: RedactionStampParams): void {
        Object.assign(this, params);
    }

    getContent(): string {
        return this.content;
    }

    getAbbreviation(): string {
        return this.abbreviation;
    }

    getUser(): User | null {
        return this.userId ? Base.get(User, this.userId) : null;
    }

    isProjectStamp(): boolean {
        return this.userId === 0;
    }

    override display(): string {
        let disp = this.getContent();
        const abbr = this.getAbbreviation();
        if (abbr) {
            disp += " (" + abbr + ")";
        }
        return disp;
    }

    override equals(other: RedactionStamp): boolean {
        if (!other) {
            return false;
        }
        return (
            other.getContent() === this.getContent()
            && other.getAbbreviation() === this.getAbbreviation()
        );
    }
}

module RedactionStamp {
    export interface Stampable {
        redactionStamp: RedactionStamp;
        /**
         * This function is used to determine what the size of a stamp would be on a redaction.
         * A dynamic size is returned for resizable redactions (SVGImageAnnotation).
         * An arbitrary truthy value means the size doesn't matter (such as Media / Spreadsheet).
         * A null or 0 value means the stamp is always hidden (Metadata).
         * The return value decides whether the `eye-off` icon shows up next to the stamp in the
         * selector.
         */
        getStampSize: (stamp: RedactionStamp) => number;
    }

    // noStamp is now used in place of null redaction stamps to pass strict null checks.
    // The stamp id for no stamp is undefined.
    export const noStamp = new RedactionStamp({ content: "", abbreviation: "" });
}

export = RedactionStamp;
