/**
 * The frequency with which we'll show a {@link Recommendation}.
 */
export enum RecommendationLevel {
    /**
     * The {@link Recommendation} wll be shown per every (user, project) pair.
     */
    PROJECT_LEVEL,
    /**
     * The {@link Recommendation} will be shown only once per the given user.
     */
    USER_LEVEL,
    /**
     * The {@link Recommendation} will always be shown if the triggering event occurs regardless
     * of whether it had been shown before.
     */
    EVERYTIME_LEVEL,
}
