import * as Rest from "Everlaw/Rest";
import { RecommendationAcknowledged } from "Everlaw/SmartOnboarding/RecommendationAcknowledged";
import { Recommendations } from "Everlaw/SmartOnboarding/RecommendationConstants";

/**
 * This data can be tracked and loaded in from the backend.
 */
export interface RecommendationStatusRestData {
    recommendation: keyof typeof Recommendations;
    triggered: boolean;
    shown: boolean;
    lastModified: number;
    acknowledged?: RecommendationAcknowledged;
    baseObjectId?: number;
    history?: number[];
    step?: number;
    uriFrom?: string;
    projectId?: number;
    orgId?: number;
}

/**
 * The minimum data we need to commit a {@link RecommendationStatus} to the backend.
 */
export interface RecommendationStatusCommitRestData {
    recommendation: keyof typeof Recommendations;
    recommendationStatus: string;
}

/**
 * The status of a {@link Recommendation} for the current user on the current page.
 */
export class RecommendationStatus {
    /**
     * The {@link Recommendation} associated with this status.
     */
    recommendation: keyof typeof Recommendations;
    /**
     * Whether an action has been taken such that we should show the {@link #recommendation} to
     * the user.
     */
    triggered: boolean = false;
    /**
     * Whether we've shown this {@link Recommendation} to the user.
     */
    shown: boolean = false;
    /**
     * Whether this {@link Recommendation} has been activated from the help menu.
     */
    fromHelpMenu: boolean = false;
    /**
     * The timestamp at which the user last interacted with the {@link Recommendation}.
     */
    lastModified: number;
    /**
     * A list of previous {@link #step steps} the user has advanced through in the
     * {@link Recommendation}.
     */
    history: number[] = [];
    /**
     * The state to which the user has interacted with this {@link Recommendation}.
     */
    acknowledged?: RecommendationAcknowledged;
    /**
     * The id of an Everlaw base object (see Base.ts#Obj) optionally linked to a
     * {@link Recommendation}. We can use this id to navigate to more specific pages. This is
     * useful in the context of the help menu. For example, given the id of a {@link
     * SearchResult#id}, we can navigate to the results table view of that search, and show
     * recommendations that specifically pertain to that search.
     *
     * The object represented by this id depends on the
     * {@link RecommendationNavigationPage navigation page} linked to a recommendation. It could be
     * a search result as with our previous example, or it could be a Deposition id if the
     * recommendation we're trying to show is linked to a specific Deposition.
     *
     * Note: this should only be set if a recommendation needs to be linked to an object! For
     * example, recommendations that have to do with configuring the results table do not need to
     * know what search triggered the recommendation.
     */
    baseObjectId?: number;
    projectId?: number;
    orgId?: number;

    /**
     * Used to send updates about this {@link RecommendationStatus} to the backend.
     */
    toCommitRestData(): RecommendationStatusCommitRestData {
        return {
            recommendation: this.recommendation,
            recommendationStatus: JSON.stringify({
                triggered: this.triggered,
                shown: this.shown,
                acknowledged: this.acknowledged,
                projectId: this.projectId,
                orgId: this.orgId,
            }),
        };
    }

    /**
     * Takes the current {@link RecommendationStatus} and commits updates to the backend. This
     * does not check if there are any updates that need to be committed, however. That is the
     * responsibility of the caller.
     *
     * @return true on successful update.
     */
    async commitUpdate(commit: boolean): Promise<boolean> {
        if (!commit || this.recommendation === "TEST_REC") {
            return false;
        }
        return Rest.post("/recommendation/commitUpdate.rest", this.toCommitRestData());
    }
}
